<ng-container *ngIf="href">
  <a class="no-link" [href]="href" [target]="target">
    <ng-container *ngTemplateOutlet="btnTemplate"></ng-container>
  </a>
</ng-container>
<ng-container *ngIf="!href">
  <ng-container *ngTemplateOutlet="btnTemplate"></ng-container>
</ng-container>
<ng-template #btnTemplate>
  <button #btn
          [type]="role"
          [disabled]="disabled"
          class="{{ _class }}"
          [ngClass]="{'cancel': cancel, 'reset': reset, 'breadcrumbs': breadcrumbs, 'white-round': white, 'options': options, 'pink': pink}"
          tabindex="-1">
    <ng-content></ng-content>
  </button>
</ng-template>
