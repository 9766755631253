import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CartCondition } from '@core/models';

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent {
  @Input() variant;
  // tslint:disable-next-line: no-output-native
  @Output() close = new EventEmitter();
  @Input() type: string;
  @Input() entityType: string;
  @Input() stockInfo?: CartCondition;
  constructor(
  ) { }

  onClose() {
    this.close.next();
  }
}
