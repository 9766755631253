export function copyright( copyicon: boolean): string {
  const copy = new Date();
  const copyyear = copy.getFullYear().toString();
  if ( copyicon === true) {
    return copyyear;
  } else {
    return '© ' + copyyear;
  }

}

