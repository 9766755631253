import { Inject, Injectable } from '@angular/core';
import { PageResult } from '@core/models/page/page.model';
import { Observable, of } from 'rxjs';
import { BASE_URL } from '@core/lib/api.factory';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CountryModel, CountryQueryModel, GqlCountryQuery } from '@core/models/hdbk/country.model';


@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private buffCountry: { [key: string]: PageResult<CountryModel[]> } = {};

  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string
  ) {
  }

  getPage(query: CountryQueryModel): Observable<PageResult<CountryModel[]>> {
    const q = new GqlCountryQuery(query);
    const qglQueryString = q.toRest();
    if (this.buffCountry[qglQueryString]) {
      return of(this.buffCountry[qglQueryString]);
    } else {
      return this.http.get<any>(`${this.baseUrl}/hdbk/geo/country?${qglQueryString}`,
        {observe: 'response'}
      ).pipe(
        map(resp => {
          this.buffCountry[qglQueryString] = new PageResult<CountryModel[]>(resp);
          return this.buffCountry[qglQueryString];
        })
      );
    }
  }
}
