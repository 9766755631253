// return .wr.market or null
export function getPublicDomain(host: string): string {
  if (host.indexOf('localhost') !== -1) {
    return null;
  } else {
    const hs = host.split('.');
    if (hs.length === 2) {
      return `.${host.replace('https://', '')}`;
    } else {
      return `.${hs[hs.length - 2]}.${hs[hs.length - 1]}`;
    }
  }
}
