import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { FavoritesAddQuery, GqlFavoritesQuery, FavoritesModel, FavoritesQuery } from '@core/models/user/favorites.model';
import { map, catchError, tap } from 'rxjs/operators';
import { PageResult } from '@core/models/page/page.model';
import { throwError, Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class FavoritesService {
  favoritesChange$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, @Inject(BASE_URL) private baseUrl: string) { }

  getFavorites(query: FavoritesQuery): Observable<PageResult<FavoritesModel[]>> {
    const gqlQuery = new GqlFavoritesQuery(query);
    return this.http
      .get<any>(`${this.baseUrl}/user/favorite?${gqlQuery.toRest()}`, { observe: 'response' })
      .pipe(
        map(res => {
          return new PageResult<FavoritesModel[]>(res);
        }),
        catchError(err => throwError(err))
      );
  }
  addToFavorites(query: FavoritesAddQuery) {
    return this.http.post<any>(`${this.baseUrl}/user/favorite`, query).pipe(tap(() => {
      this.favoritesChange$.next(null);
    }));
  }
  removeFromFavorites(query: FavoritesAddQuery) {
    const options = {
      headers: new HttpHeaders(),
      body: query
    };
    return this.http.delete(`${this.baseUrl}/user/favorite`, options).pipe(tap(() => {
      this.favoritesChange$.next(null);
    }));
  }
}
