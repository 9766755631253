import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export class Stock {
  id: number;
  name: string;
  is_active: boolean;
  start_at: string;
  end_at: string;
  description: string;
  reward: string;
  reward_type: string;
  isLoading?: boolean;
  key: string;
  need_to_calculate_points: boolean;
}

export class StockSelectQuery {
  constructor(public user_stock_id: number) {
  }
}

export class StockQuery {
  city_id: number;
}

export const gqlStockFields = `{
  id
  name
  is_active
  start_at
  end_at
  description
  reward
  reward_type
  key
  need_to_calculate_points
}`;

export class GqlStockQuery extends GqlQuery {
  fields = gqlStockFields;
}
