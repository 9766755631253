<app-cookies></app-cookies>

<footer class="footer">
  <div class="container">
    <div class="footer__col">
      <div class="footer__col--title" i18n>Online shop</div>
      <div class="footer__menu">
        <a routerLink="/catalog" class="footer__menu--link" i18n='@@common.catalogue'>Catalogue</a>
        <a routerLink="/delivery" class="footer__menu--link" target="_blank" i18n>Delivery</a>
        <!--
        <a href="#" class="footer__menu--link">Оплата</a>
        -->
      </div>
    </div>

    <div class="footer__col md">
      <div class="footer__col--title">Vertera</div>
      <div class="footer__menu">
        <!-- <a routerLink="/about" class="footer__menu--link" i18n>About company</a> -->
        <!--
        <a href="https://old.dev.vertera.org/geography" target="_blank" class="footer__menu--link">География</a>
        <a href="#" class="footer__menu--link">Партнерская программа</a>
        -->
        <a routerLink="/news" class="footer__menu--link" i18n="@@common.news">News</a>
        <a *ngIf="!user" href="https://os.verteraorganic.com/lkc.php?logout=yes" class="footer__menu--link" target="_blank" i18n>Partner's office</a>
      </div>
      <div class="footer__menu">
        <a routerLink="/faq" target="_blank" class="footer__menu--link" i18n>Help</a>
        <a *ngIf='false' routerLink="/callback" class="footer__menu--link" i18n>Call order</a>

        <a *ngIf="!user" (click)='login()' class="footer__menu--link" i18n="@@sign_in">
          Sing in
        </a>
        <a *ngIf="!user" (click)='register()' class="footer__menu--link" i18n="@@sing_up">
          Sign up
        </a>
        <a *ngIf="user" class="footer__menu--link" target="_blank" (click)='goToPartnerCabinet()' i18n>Partner's office</a>
      </div>
    </div>

    <div class="footer__col lg-max">
      <div class="footer__col--title" >Vertera</div>

      <div class="footer__menu">
        <!-- <a routerLink="/about" class="footer__menu--link" i18n>About company</a> -->
        <!--
        <a href="https://old.dev.vertera.org/geography" target="_blank" class="footer__menu--link">География</a>
        <a href="#" class="footer__menu--link">Партнерская программа</a>
        -->
        <a routerLink="/news" class="footer__menu--link" i18n="@@common.news">News</a>
        <a *ngIf="!user" href="https://os.verteraorganic.com/lkc.php?logout=yes" class="footer__menu--link" target="_blank" i18n>Partner's office</a>
      </div>
    </div>

    <div class="footer__col lg-max">
      <div class="footer__menu custom-block">
        <a routerLink="/faq" target="_blank" class="footer__menu--link" i18n>Help</a>
        <a *ngIf='false' routerLink="/callback" class="footer__menu--link" i18n>Call order</a>
        <a *ngIf="!user" (click)='login()' class="footer__menu--link" i18n="@@sign_in">
          Sing in
        </a>
        <a *ngIf="!user" (click)='register()' class="footer__menu--link" i18n="@@sing_up">
          Sign up
        </a>
        <!--
        <a *ngIf="user" (click)='goToUserCabinet()' class="footer__menu--link" i18n>
          Personal cabinet
        </a>
        -->
        <a *ngIf="user" class="footer__menu--link" (click)='goToPartnerCabinet()' i18n>Partner's office</a>
      </div>
    </div>

    <div class="footer__col">
      <div class="footer__social">
        <div class="footer__col--title" i18n>WE IN SOCIAL NETWORKS</div>
        <a *ngIf="lang !== 'ru'" href="https://www.facebook.com/verteraorganic" class="footer__social--link" target="_blank" >
          <svg-icon name="social--fb" class="fb"></svg-icon>
        </a>
        <a href="https://vk.com/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--vk" class="vk"></svg-icon>
        </a>
        <a *ngIf="lang !== 'ru'" href="https://www.instagram.com/vertera_company/" class="footer__social--link" target="_blank">
          <svg-icon name="social--inst" class="inst"></svg-icon>
        </a>
        <a href="https://www.youtube.com/user/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--youtube" class="youtube"></svg-icon>
        </a>
        <a href="https://ok.ru/verteraorganic" class="footer__social--link" target="_blank">
          <svg-icon name="social--ok" class="ok"></svg-icon>
        </a>
      </div>
      <div class="footer__contact">
        <a href="tel:+994513314291" class="footer__contact--phone">994 51 331 42 91</a>
        <span class="footer__contact--text" i18n>
          Daily from 9:00 to 19:00<br>Moscow time
        </span>
        <!-- <a href="tel:+79201916187" class="footer__contact--sphone">7 (920) 191-61-87</a> -->
      </div>
    </div>
  </div>
  <div class="footer__bottom">
    <div class="container">
      <div class="footer--half">
        <span class="footer--copy">&copy;</span>
        <span i18n>2013 - {{copyright}} Vertera. Enjoy life with Vetera natural products!</span>
      </div>
      <div class="footer--half">
        <!--
          <a routerLink="/returns" target="_blank" class="footer--link" *appIfLang="['en', 'bg', 'es', 'cs', 'de']">Retuns & Refunds Policy</a>
        -->
        <a routerLink="/policy" target="_blank" class="footer--link" i18n>Privacy policy</a>
        <a routerLink="/terms" target="_blank" class="footer--link" i18n>Terms of use</a>
      </div>
    </div>
  </div>
  <div class="footer__scroll" [ngClass]="{ 'scroller': scroller }" (click)="scrollToTop()">
    <img appStatic="/assets/icons/ic/scroll-top.svg" />
  </div>
</footer>
