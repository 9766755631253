import { OnInit, OnDestroy, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, from, Subscription, of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CityService } from '@core/services/hdbk/city.service';
import { CountryService } from '@core/services/hdbk/country.service';
import { debounceTime, filter, map, switchMap, catchError, take } from 'rxjs/operators';
import { CityQueryModel } from '@core/models/geo/city.model';
import { CountryModel, CountryQueryModel, GeoEntity } from '@core/models/hdbk/country.model';
import { GeoService } from '@core/services/geo.service';
import { PageResult } from '@core/models/page/page.model';
import { LanguageService } from '@core/services/language.service';
import { MatDialog } from '@angular/material';
import { CityQuery } from '@core/models/hdbk/city.model';

declare var ymaps: any;

export abstract class Geo implements OnInit, OnDestroy {
  readonly sub$: Subscription = new Subscription();
  submitted: boolean;
  isLoading: boolean;
  city: GeoEntity;
  country: GeoEntity;
  // set this property to true in child classes if you don't need geo detection
  protected skipGeoDetection: boolean;
  yaGeolocation: any;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onSubmit = new EventEmitter();

  @Input() productIds?: number;

  protected onCitySelected$ = new EventEmitter();
  protected onCountryPreselected$ = new EventEmitter();

  protected constructor(
    protected formBuilder: FormBuilder,
    protected cityService: CityService,
    protected countryService: CountryService,
    protected changeDetector: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router,
    protected geoService: GeoService,
    protected languageService: LanguageService,
    protected dialog: MatDialog,
  ) {
  }

  form: FormGroup = this.formBuilder.group({
    country_id: [this.cityService.CITY.default.country.id, [Validators.required]],
    city_id: ['', [Validators.required]],
    language: ['az']
  });

  get formControls() {
    return this.form.controls;
  }

  ngOnInit() {
    this.city = new GeoEntity(new CityQueryModel());
    this.country = new GeoEntity(new CountryQueryModel());
    this.subscribeToSearch(this.city);
    const _city = this.cityService.getCity();
    // this.getCountries();
    const defaultCountry = this.cityService.CITY.default.country;
    this.country.entitiesForDD = [defaultCountry];
    this.country.selected = defaultCountry;
    this.formControls.country_id.patchValue(defaultCountry.id);
    this.preselectLanguage();
    if (_city) {
      this.city.entitiesForDD = [_city];
      this.city.selected = _city;
      this.formControls.city_id.patchValue(_city.id);
      // this.cityService.cityChange$.next(_city);
    }
    this.changeDetector.detectChanges();
    this.subscribeToRouter();
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  onCitySelected(city: any) {
    this.formControls.city_id.setValue(city.id);
    this.city.selected = city;
    this.onCitySelected$.emit(city);

    if (/\/catalog\//.test(this.router.url)) {
      this.router.navigate([], { // trigger catalog filter
        queryParams: { city_id: city.id },
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        skipLocationChange: true,
      });
    }
    this.changeDetector.detectChanges();
  }

  onCountrySelected(country: CountryModel) {
    this._handleCountrySelection(country, true)
  }

  private _handleCountrySelection(country: CountryModel, isManualSelect: boolean) {
    // selectFunc - ation that performs if country belog to this type of website
    // const selectFunc = isManualSelect ? this._countrySelected : this.handleCountryPreselect;
    if (country) {
      this._countrySelected(country)
      /*
      country.alpha2 === 'TR'
        ? this.checkTurkey()
        : this._countrySelected(country);
      */
    }
  }

  private _countrySelected(country: any) {
    this.formControls.country_id.setValue(country.id);
    this.country.selected = country;
    this.resetCity();
  }

  submitFirstVisit() {
    if (this.form.valid && this.city.selected) {
      this.cityService.setCity(this.city.selected);
    }
    this.goBack();
  }

  searchCity(event) {
    this.search(event, this.city);
  }

  searchCountry(event) {
    this.search(event, this.country, false);
  }

  private search(event, geo, clearList = true) {
    if (clearList) {
      geo.entitiesForDD = [];
    }
    geo.allowSearch$.next(!!event.term);
    this.changeDetector.detectChanges();
  }

  subscribeToSearch(geo: GeoEntity) {
    // tslint:disable-next-line: deprecation
    const sub = combineLatest(geo.search$, geo.allowSearch$).pipe(
      debounceTime(500),
      filter(([term, allow]) => allow && !!term),
      map(([term, allow]) => term),
      switchMap((term: string) => {
        geo.isLoading = true;
        geo.query.search = term;
        if (this.formControls.country_id.value) {
          geo.query.country_id = this.formControls.country_id.value;
        }
        this.modifyQuery(geo.query);
        this.changeDetector.detectChanges();
        return this.cityService.getPage(geo.query);
      }),
      filter(res => res && !!res.data),
      catchError(err => of(err)),
      map(res => {
        if (res instanceof Error) {
          // Maybe do some error handling here
          return null;
        }
        return res.data;
      }),
    ).subscribe(res => {
      geo.entitiesForDD = res;
      geo.isLoading = false;
      this.changeDetector.detectChanges();
    }, (err) => {
      geo.isLoading = false;
      this.changeDetector.detectChanges();
    });
    this.sub$.add(sub);
  }

  preselectCountry() {
    const city = this.cityService.getCity();
    this.formControls.country_id.patchValue((city && city.country && city.country.id)?
           city.country.id : this.cityService.CITY.default.country.id);
    this._countrySelected(city ? city.country : this.cityService.CITY.default.country);
    if (!this.skipGeoDetection) {
      this.runGeoDetection();
    }
  }

  runGeoDetection() {
    this.sub$.add(
      from(this.geoService.loadDetectScript()).subscribe(() => {
        ymaps.ready(() => {
          if (ymaps.geolocation) {
            this.ymapsReadyGeo(ymaps.geolocation);
          }
        });
      })
    );
  }

  private ymapsReadyGeo(geolocation: any) {
    this.yaGeolocation = geolocation;
    const query = new CountryQueryModel();
    query.search = geolocation.country;
    this.sub$.add(
      this.countryService.getPage(query).subscribe( country => {
        if (country.data.length) {
          if (country.data[0].alpha2 === 'TR') {
            // this.checkTurkey();
          } else {
            this.formControls.country_id.patchValue(country.data[0].id);
            if (country.data[0].id !== this.cityService.CITY.default.country.id) {
              this.resetCity();
            }
            this.onCountryPreselected$.emit(country.data[0]);
            this.resetCity();
            const cityQuery = new CityQuery();
            cityQuery.country_id = country.data[0].id;
            cityQuery.search = geolocation.city;
            delete cityQuery.has_storage;
            const sub = this.cityService.getCities(cityQuery).subscribe((cities) => {
              let city = cities.find(item => item.name.toLowerCase() === geolocation.city.toLowerCase());
              if (!city) {
                city = cities.filter(item => item.name.toLowerCase().includes(geolocation.city.toLowerCase()))[0] || cities[0];
              }
              if (city) {
                this.preselectCity(city);
              }
            });
            this.sub$.add(sub);
          }
        } else {
          this.selectDefaultCapital();
        }
        this.changeDetector.detectChanges();
      },
        (err) => this.formControls.country_id.patchValue(this.cityService.CITY.default.country.id)
      )
    );
  }

  private getCountries() {
    // tslint:disable-next-line: no-shadowed-variable
    const query = new CountryQueryModel();
    this.sub$.add(
      this.countryService.getPage(query).subscribe((data: PageResult<CountryModel[]>) => {
        this.country.entitiesForDD = data.data;
        this.preselectCountry();
        this.selectDefaultCapital(); // TODO: fix this logic
      })
    );
  }

  private preselectLanguage() {
    this.formControls.language.patchValue('az');
  }


  preselectCity(exactCity: any = null) {
    const city = exactCity ? exactCity : this.cityService.getCity();
    if (city) {
      if (this.formControls.country_id.value === city.country.id) {
        this.city.entitiesForDD = [city];
        this.onCitySelected(city);
      }
    }
  }

  skipCitySelection(navigate = true) {
    // preselect moscow by default
    const defaultCity = this.cityService.CITY.default;
    this.cityService.setCity(this.city.selected);
    if (navigate) {
      this.onCitySelected(defaultCity);
      this.goBack();
    }
  }

  goBack(): void {
    if (this.geoService.previousUrl) {
      if(this.geoService.previousQueryParams) {
        delete this.geoService.previousQueryParams.global;
      }
      this.router.navigate(
        [this.geoService.previousUrl],
        this.geoService.previousQueryParams ? { queryParams: this.geoService.previousQueryParams } : {}
      );
    } else {
      this.router.navigate(['../'], {relativeTo: this.route});
    }
  }

  resetCity() {
    this.city.entitiesForDD = [];
    this.city.selected = null;
    this.form.controls.city_id.reset();
  }


  selectDefaultCapital() {
    const city = this.cityService.getCity();
    this.preselectCity( city ? city : this.cityService.CITY.default);
    this.changeDetector.detectChanges();
  }

  subscribeToCityChange() {
    const sub = this.cityService.cityChange$.subscribe((city: any) => {
      if (this.city.selected && city.id !== this.city.selected.id) {
        this.formControls.country_id.patchValue(city ? city.country.id : this.cityService.CITY.moscow.country.id);
        this.city.entitiesForDD = [city];
        this.formControls.city_id.setValue(city.id);
        this.city.selected = city;
        this.changeDetector.detectChanges();
      }
    });
    this.sub$.add(sub);
  }

  subscribeToRouter() {
    const sub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd), take(1))
      .subscribe((event: NavigationEnd) => {
        const urlWithoutParams = event.url.replace(/\?.*/, '');
        if (urlWithoutParams !== '/geo-lang') {
          this.geoService.previousUrl = urlWithoutParams;
          this.geoService.previousQueryParams = Object.assign({}, this.route.snapshot.queryParams);
        }
      });
    this.sub$.add(sub);
  }

  abstract modifyQuery(query);
}
