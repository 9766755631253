<div class="notification-top green" *ngIf='isShow && notifer && notifer.type === "top"' @fade>
  <div class="container">
    <svg-icon name="ic--checkmark-white" size="20"></svg-icon>
    <span i18n="@@register_success">Your account was successfully registered</span>
  </div>
</div>
<div class="notification-content" *ngIf="isShow && notifer && notifer.type !== 'stock' && notifer.type !== 'top'" @fade>
  <ng-container *ngIf="template">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ng-container>
  <ng-container *ngIf="!template && notifer" [ngSwitch]="notifer.type">
    <ng-container *ngSwitchCase="'to-cart'">
      <app-notifier [variant]="notifer.data" [type]='notifer.type' [entityType]='notifer.entityType' (close)="hide()">
      </app-notifier>
    </ng-container>
    <ng-container *ngSwitchCase="'to-fav'">
      <app-notifier [variant]="notifer.data" [type]='notifer.type' (close)="hide()" [entityType]='notifer.entityType'>
      </app-notifier>
    </ng-container>
    <ng-container *ngSwitchCase="'from-fav'">
      <app-notifier [variant]="notifer.data" [type]='notifer.type' (close)="hide()" [entityType]='notifer.entityType'>
      </app-notifier>
    </ng-container>
    <div class="text-container" *ngSwitchCase="'literal'">
      <svg-icon name="close" class="close close_btn" (click)="hide()"></svg-icon>
      <h2 class="title" *ngIf="notifer.data.title">{{ notifer.data.title | vrLiteral | async }}</h2>
      <p class="description">{{ notifer.data.description | vrLiteral | async }}</p>
    </div>
    <div class="text-container" *ngSwitchDefault>
      <ng-container *ngIf='notifer.data'>
        <svg-icon name="close" class="close close_btn" (click)="hide()"></svg-icon>
        <h2 class="title" *ngIf="notifer.data.title">{{ notifer.data.title }}</h2>
        <p class="description">{{ notifer.data.description }}</p>
      </ng-container>
    </div>
  </ng-container>
</div>
<div class="notification__stock" *ngIf='isShow && notifer && notifer.type === "stock"' @fade>
  <div class="container">
    <svg-icon class="notification__stock--icon" name="ic--checkmark-small-t" size="17"></svg-icon>
    <div class="notification__stock--text" i18n='@@stocks.apply_notification'>Promotional «{{notifer.data.title}}» was applied</div>
    <svg-icon (click)='close()' class="notification__stock--close" name="control--close-small" size="12"></svg-icon>
  </div>
</div>
<div class="notification__stock" *ngIf='isShow && notifer && notifer.type === "withdraw"' @fade>
  <div class="container">
    <svg-icon class="notification__stock--icon" name="ic--checkmark-small-t" size="17"></svg-icon>
    <div class="notification__stock--text" i18n='@@finance.withdraw_notification'>Your application has been submitted and will be processed within 2 working days</div>
    <svg-icon (click)='close()' class="notification__stock--close" name="control--close-small" size="12"></svg-icon>
  </div>
</div>
