import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { CategoryModel, CategoryQueryModel, GqlCategoryQuery } from '@core/models/store/category/category.model';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CityService } from '@core/services/hdbk/city.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  categories: CategoryModel[];
  categories$ = new ReplaySubject<CategoryModel[]>(1);
  countryId: number;

  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    private cityService: CityService,
  ) { }

  getCategories(): Observable<CategoryModel[]> {
    const query: CategoryQueryModel = new CategoryQueryModel(this.cityService.getCountryId() || 113);
    const isCountryChanged = this.countryId !== query.country_id;
    if (!this.categories || isCountryChanged) {
      this.countryId = query.country_id;
      const gqlQuery = new GqlCategoryQuery(query);
      return this.http.get<{data: CategoryModel[]}>(`${this.baseUrl}/store/category?${gqlQuery.toRest()}`).pipe(
        map( res => {
          if (isCountryChanged) {
            this.categories = res.data;
          }
          this.categories$.next(res.data);
          return res.data;
        })
      );
    } else {
      return of(this.categories);
    }
  }
}
