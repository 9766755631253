import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { LanguageService } from '@core/services/language.service';
import { Observable } from 'rxjs';


@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  expression: RegExp = new RegExp('vertera\.org');

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.expression.test(req.url)) {
      const language = this.injector.get(LanguageService);
      const languageUrl = req.url.replace('vertera.org/ru/v1', `vertera.org/${language.getLanguage()}/v1`);
      req = req.clone({url: languageUrl});
    }
    return next.handle(req);
  }
}
