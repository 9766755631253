import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CheckUserCreateService } from './check-user-create.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthMethodService {
  private isUseNewEca$$ = new BehaviorSubject<boolean | undefined>(true);

  constructor(private checkUserCreateService: CheckUserCreateService) { }

  getIsUseNewEca$(): Observable<boolean | undefined> {
    return this.isUseNewEca$$.asObservable();
  }

  getIsUseNewEca(): boolean | undefined {
    return this.isUseNewEca$$.value;
  }

  fetch(): Observable<boolean> {
    return this.checkUserCreateService.checkUserCreate().pipe(map((res: string) => {
      const isUseNewEca = !(+res);
      this.isUseNewEca$$.next(isUseNewEca);
      return isUseNewEca;
    } ));
  }

}
