import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckBoxComponent } from './check-box.component';
import { RadioComponent } from './radio/radio.component';



@NgModule({
  declarations: [CheckBoxComponent, RadioComponent],
  exports: [
    CheckBoxComponent,
    RadioComponent
  ],
  imports: [
    CommonModule
  ]
})
export class CheckBoxModule { }
