import { Injectable, Inject } from '@angular/core';
import { GqlOrderQuery, Order, OrderQuery, OrderFilterQuery, OrderEditQuery, PageFilterResult } from '@core/models/order/order.model';
import { AppStorage } from '@core/storage';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { VRStorage } from '@core/models/storage/storage.model';
import { RefService } from '@core/services/user/ref.service';
import { QueryLogService } from '../common/query-log.service';
import { StorageService } from '@core/services/storage/storage.service';

@Injectable()
export class OrderService {

  constructor(
    @Inject(AppStorage) private appStorage: Storage,
    @Inject(BASE_URL) private baseUrl: string,
    private route: ActivatedRoute,
    private http: HttpClient,
    private refService: RefService,
    private queryLogService: QueryLogService,
    public storageService: StorageService,
  ) { }


  getOrder(orderId: number): Observable<Order> {
    const gqlQuery = new GqlOrderQuery();
    return this.http.get<{ data: Order }>(
      `${this.baseUrl}/store/order/${orderId}?${gqlQuery.toRest()}`
    ).pipe(
      map(res => res.data),
      catchError(err => throwError(err))
    );
  }

  getOrders(query?: OrderFilterQuery): Observable<PageFilterResult<Order[]>> {
    const gqlQuery = new GqlOrderQuery(query);
    return this.http.get<any>(
      `${this.baseUrl}/store/order?${gqlQuery.toRest()}`, { observe: 'response' }
    ).pipe(
      map(res => new PageFilterResult<Order[]>(res)),
      catchError(err => throwError(err))
    );
  }

  sendOrder(query: OrderQuery): Observable<Order> {
    const gqlQuery = new GqlOrderQuery();
    const ref_code = this.route.snapshot.queryParamMap.get('ref') || this.refService.getRef();
    if (ref_code) {
      query.ref_code = ref_code;
    }
    const storage: VRStorage = this.storageService.getStorage();
    if (storage) {
      query.storage_id = storage.id;
    }
    query.stat_uuid = this.queryLogService.getUUID();
    return this.http.post<{ data: Order }>(`${this.baseUrl}/store/order?${gqlQuery.toRest()}`, query).pipe(
      map(res => {
        if (res.data) {
          this.setOrderId(res.data.id);
        }
        return res.data;
      })
    );
  }

  editOrder(orderId, query: OrderEditQuery): Observable<Order> {
    const gqlQuery = new GqlOrderQuery();
    return this.http.put<{ data: Order }>(`${this.baseUrl}/store/order/${orderId}?${gqlQuery.toRest()}`, query).pipe(map(res => res.data));
  }

  setOrderId(orderId: number) {
    this.appStorage.setItem(`${environment.appName}_orderId`, orderId.toString());
  }

  copyOrder(orderId: number, city_id: number) {
    return this.http.put(`${this.baseUrl}/store/order/${orderId}/copy`, {city_id});
  }

  getOrderId(): number {
    return +this.appStorage.getItem(`${environment.appName}_orderId`);
  }
}
