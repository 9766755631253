import { OnDestroy, OnInit } from '@angular/core';
import {
  CategoryChildModel,
  CategoryGrandParentModel,
  CategoryModel, CategoryParentDepth3Model,
  CategoryParentModel
} from '@core/models/store/category/category.model';
import { CategoryService } from '@core/services/category/category.service';
import { CityService } from '@core/services/hdbk/city.service';

import { Subscription } from 'rxjs';

export abstract class CategoryTreeBase implements OnInit, OnDestroy {

  readonly sub$: Subscription = new Subscription();

  categories: CategoryGrandParentModel[];

  constructor(
    protected categoryService: CategoryService,
    protected cityService: CityService,
  ) {
  }

  buildTreeFromArray(arr: CategoryModel[]): CategoryGrandParentModel[] {
    const result: CategoryGrandParentModel[] = [];
    let lastGrandParent: CategoryGrandParentModel;
    let lastParent: CategoryParentModel;
    let lastParentDepth3: CategoryParentDepth3Model;
    for (const category of arr) {
      const url = `/catalog/${category.slug}`;
      if (category.depth === 1) {
        const grandParentCategory = Object.assign({children: [], url}, category) as CategoryGrandParentModel;
        result.push(grandParentCategory);
        lastGrandParent = grandParentCategory;
      } else if (category.depth === 2) {
        const parentCategory = Object.assign({children: [], parent: lastGrandParent, url}, category) as CategoryParentModel;
        if (lastGrandParent) {
          lastGrandParent.children.push(parentCategory);
          lastParent = parentCategory;
        }
      } else if (category.depth === 3) {
        const parentDepth3Category = Object.assign({children: [], parent: lastParent, url}, category) as CategoryParentDepth3Model;
        lastParent.children.push(parentDepth3Category);
        lastParentDepth3 = parentDepth3Category;
      } else if (category.depth === 4) {
        const childCategory = Object.assign({parent: lastParentDepth3, url}, category) as CategoryChildModel;
        lastParentDepth3.children.push(childCategory);
      }
    }
    return result;
  }

  ngOnInit() {
    this.subscribeToCityChange();
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  subscribeToCityChange() {
    const sub = this.cityService.cityChange$.subscribe(() => {
      this.getCategories();
    });
    this.sub$.add(sub);
  }

  getCategories() {
    const sub = this.categoryService.getCategories().subscribe(categories => {
      this.categories = this.buildTreeFromArray(categories);
      this.calcWidth();
    });
    this.sub$.add(sub);
  }

  abstract calcWidth(): void;

}