import { Injectable, Inject } from '@angular/core';
import { BASE_URL } from 'src/app/core/lib/api.factory';
import {
  Product, GqlProductQuery, ProductQuery, Price, GqlProductFiltersQuery, ProductFilter, ProductsFilterRequest,
  GqlProductsQuery, GqlCitiesFiltersQuery, FilterCount, GqlFilteCountsQuery,
} from '../../models/product/product.model';
import { Observable, ReplaySubject, throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PageResult } from '@core/models/page/page.model';
import { CurrencyService } from '../currency.service';
import { CityService } from '../hdbk/city.service';

@Injectable()
export class ProductService {
  public currentProduct: Product;
  public product$ = new ReplaySubject<Product>(1);
  public products$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private currencyService: CurrencyService,
    @Inject(BASE_URL) private baseUrl: string,
    private cityService: CityService,
  ) {
  }

  getProducts(query: ProductQuery): Observable<Product[]> {
    this.setQueryCountry(query);
    const gqlQuery = new GqlProductsQuery(query);
    return this.http
      .get<{ data: Product[] }>(
        `${this.baseUrl}/store/product?is_hidden=0&${gqlQuery.toRest()}`
      )
      .pipe(
        map(res => {
          this.parseProductsPrices(res.data);
          this.setCurrency(res.data);
          return res.data;
        }),
        catchError(err => throwError(err))
      );
  }

  getProduct(slug: string, country_id: number): Observable<Product> {
    const query = new ProductQuery();
    if (country_id) {
      query.country_id = country_id;
    }
    const gqlQuery = new GqlProductQuery(query);
    return this.http
      .get<{ data: Product }>(
        `${this.baseUrl}/store/product/${slug}?${gqlQuery.toRest()}`
      ).pipe(
        map(res => {
          const product = res.data;
          if (product.related) {
            product.related = product.related.filter((p, index) => index < 15);
          }
          this.currentProduct = product;
          this.product$.next(product);
          return product;
        }),
        catchError(err => throwError(err))
      );
  }

  getInstancesWithPagination(query: ProductQuery): Observable<PageResult<Product[]>> {
    const q = Object.assign({}, query);
    this.setQueryCountry(q);
    const gqlQuery = new GqlProductQuery(q);
    return this.http
      .get<any>(`${this.baseUrl}/store/product?is_hidden=0&${gqlQuery.toRest()}`, { observe: 'response' })
      .pipe(
        map(res => {
          this.products$.next();
          return new PageResult<Product[]>(res);
        }),
        catchError(err => throwError(err))
      );
  }

  getFilters(query: ProductsFilterRequest): Observable<ProductFilter> {
    const q = Object.assign({}, query);
    this.setQueryCountry(q);
    const gqlQuery = new GqlProductFiltersQuery(q);
    return this.http
      .get<any>(`${this.baseUrl}/store/product/filters?is_hidden=0&${gqlQuery.toRest()}`)
      .pipe(
        map(res => this.mapDefaultFilters(res.data)),
        catchError(err => throwError(err))
      );
  }

  getCount(query: ProductsFilterRequest): Observable<FilterCount> {
    const q = Object.assign({}, query);
    this.setQueryCountry(q);
    const gqlQuery = new GqlFilteCountsQuery(q);
    return this.http
      .get<any>(`${this.baseUrl}/store/product/filters?is_hidden=0&${gqlQuery.toRest()}`)
      .pipe(
        map(res => res.data),
        catchError(err => throwError(err))
      );
  }

  getGeoFilters(query: ProductsFilterRequest) {
    const q = Object.assign({}, query);
    this.setQueryCountry(q);
    const gqlQuery = new GqlCitiesFiltersQuery(q);
    return this.http
      .get<any>(`${this.baseUrl}/store/product/geo-filters?is_hidden=0&${gqlQuery.toRest()}`)
      .pipe(
        map(res => this.mapDefaultFilters(res.data)),
        catchError(err => throwError(err))
      );
  }

  public parseProductsPrices(products: Product[]) {
    products.forEach((product: Product) => {
      this.parseProductPrice(product);
    });
  }

  setCurrency(products: Product[]) {
    if (products && products.length > 0) {
      this.currencyService.setCurrency(products[0].currency);
    }
  }


  public parseProductPrice(product: Product) {
    product.price = {};
    if (product.prices) {
      product.prices.forEach((price: Price) => {
        product.price[price.currency_code] = price;
      });
    }
  }

  private setQueryCountry(query: ProductQuery | ProductsFilterRequest) {
    const city = this.cityService.getCity();
    if (city && city.country && !query.country_id) {
      query.country_id = city.country.id;
    }
  }

  mapDefaultFilters(model: ProductFilter) {
    if (!model.categories) {
      model.categories = [];
    }
    if (!model.selected_categories) {
      model.selected_categories = [];
    }
    if (!model.selected_storages) {
      model.selected_storages = [];
    }
    return model;
  }
}
