import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutWords'
})

export class CutWordsPipe implements PipeTransform {

  wordPattern(limit) {
    return new RegExp(`^.{1,${limit - 3}}\\s`);
  }

  charPattern(limit) {
      return new RegExp(`^.{1,${limit}}`);
  }

  transform(value: string, limit: number, hard?: string): string {
      if (value && value.length > limit) {
          const pattern = hard ? this.charPattern : this.wordPattern;
          return value.match(pattern(limit)) + '...';
    } else {
      return value;
    }
  }
}
