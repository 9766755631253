import { Product } from '@core/models/product/product.model';
import { User } from '@core/models/user/user.model';
import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';
import { VRStorage } from '../storage/storage.model';

export type DeliveryTypeId = 1 | 2 | 3 | 4 | 5;

export enum DeliveryTypeIds {
  self_pickup = 1,
  russian_post,
  courier,
  dpd_courier,
  ozon_pickpoint,
}

export enum StockTypeIds {
  turbo_start = 'turbostart0520'
}
export interface CartItem {
  amount: number;
  created_at: string;
  currency_code: string;
  product: Product;
  user: User;
  points_by_user: number;
  is_selected: boolean;
  is_delivery_available: boolean;
  reward_products?: CartReward[];
  available_reward_count: number;
  current_reward_count: number;
  reward_group_index: number;
}

export class CartReward {
  amount: number;
  reward_type: string;
  product: Product;
  reward_group_index: number;
  available_reward_count: number;
  current_reward_count: number;
  disabled?: boolean;
}
export interface CartDelivery {
  id: number;
  storage: VRStorage;
  price: number;
  min_price: number;
  currency_code: string;
  address: string;
  markup: number;
  type: {
    id: DeliveryTypeId,
    name: string,
  };
  selected?: boolean;
  type_calculation: {
    id: number,
    name: string
  };
}

export class CartUpdateCityQuery {
  slugs?: string;
  new_city_id: number;
}

export class CartIncrementQuery {
  city_id: number;
  storage_id: number;
  constructor(
    public slug: string,
  ) {
  }
}

export class CartRewardIncrementQuery {
  reward_group_index: number;
  reward_slug: string;
  amount: number;
}

export class StockInfoQuery {
  constructor(
    public reward_group_index: number,
  ) {
  }
}

export class CartRewardInfo {
  product_reward_slug: string;
  available_reward_count: number;
  current_reward_count: number;
}

export class CartCondition {
  params: {
    current_bpv: number;
    required_bpv: number;
    min_bpv: number;
  }
  condition: {
    id: number;
    name: string;
  };
}

export class CartChangeItemQuery {
  public amount?: number;
  public new_storage_id?: number;
  public city_id: number;
  public storage_id: number;

  constructor(
    public slug: string,
  ) {
  }
}

export class CartDeleteItem {
  public storage_id: number;
  constructor(
    public slugs: string
  ) {
  }

  get query() {
    return {
      slugs: this.slugs,
      storage_id: this.storage_id ? this.storage_id.toString() : '',
    };
  }
}

export class CartDeliveryQuery {
  country_id: number;
  city_id: number;
}

export class CartAddItem {
  slugs: string;
  product_id?: number;
  storage_id?: number;
  currency_code: string;
  amount?: number;
  city_id: number;
}

export const gqlCartProductFields = `
    id
    slug
    title
    main_image {
      id
      uri
      thumbs
    }
    status
    price_customer
    price_partner
    currency
    points_by_user
    images {
      id
      uri
    }
    points
    weight_netto
    weight_brutto
    is_pro_max
    is_halal
    is_kosher
`;

export const gqlCartFields = `{
  amount
  created_at
  currency_code
  points_by_user
  is_delivery_available
  reward_group_index
  is_selected
  product {
    ${gqlCartProductFields}
    related {
      ${gqlCartProductFields}
    }
  }
}`;

export const gqlCartDeliveryFields = `{
  id
  storage_id
  price
  min_price
  currency_code
  address
  type
  type_calculation
  storage
  markup
}`;

export const gqlCartRewardInfoFields = `{
  product_reward_slug
  available_reward_count
  current_reward_count
}`;

export const gqlCartConditionField = `{
  condition
  params
}`;

export const gqlCartRewardFields = `{
  amount
  reward_group_index
  reward_type
  product {${gqlCartProductFields}}
  available_reward_count
  current_reward_count
}`;

export class GqlCartQuery extends GqlQuery {
  fields = gqlCartFields;
}

export class GqlCartDeliveryQuery extends GqlQuery {
  fields = gqlCartDeliveryFields;
}

export class GqlCartRewardInfoQuery extends GqlQuery {
  fields = gqlCartRewardInfoFields;
}

export class GqlCartConditionsQuery extends GqlQuery {
  fields = gqlCartConditionField;
}

export class GqlCartRewardsQuery extends GqlQuery {
  fields = gqlCartRewardFields;
}
