<div class="catalog" [ngClass]="{'opened': showCatalog, 'scrolled': fixed}">
    <div class="category" #wrapper
      [ngClass]="{'show-next': showNextButton && !resetChoseCategory, 'show-prew': showPrevButton && !resetChoseCategory}">

      <!-- Button prev group -->
      <div class="category--item back-to-parent button prev"
        (click)="clickPrev()" 
        [ngClass]="{'show' : showPrevButton && !resetChoseCategory}">
        <div class="category--item--image">
          <svg-icon name="ic--arrow-bold" [size]="24"></svg-icon>
        </div>
      </div>
      <!-- Button prev group ./END-->

      <div class="category__list main" [ngClass]="{'show' : !resetChoseCategory}">
        <div class="category--item" #categoryItem *ngFor="let category of categories; index as grandParentIndex;"
          (click)="category?.children.length > 0 ? chooseCategory(categories, grandParentIndex, false) : closeCatalog()"
          [class.active]="category.active && resetChoseCategory" [routerLink]="category.url">
            <app-category-image [category]="category" class="category--item--image"></app-category-image>
            <span class="category--item--name">{{ category.name }}</span>
        </div>
      </div>

      <!-- Button next group -->
      <div class="category--item back-to-parent button next"
        (click)="clickNext()"
        [ngClass]="{'show': showNextButton && !resetChoseCategory}">
        <div class="category--item--image">
          <svg-icon name="ic--arrow-bold" [size]="24"></svg-icon>
        </div>
      </div>
      <!-- Button next group ./END -->

      <ng-container *ngFor="let category of categories; index as grandParentIndex;">

        <!-- 2 lvl category -->
        <div class="category__list second"
          [ngClass]="{'show' : category.active && resetChoseCategory}">
          <div class="category--item back-to-parent" *ngIf="category.active && category?.children.length > 0" (click)="dropChoseCategory()">
            <div class="category--item--image">
              <svg-icon name="ic--arrow-bold" [size]="24"></svg-icon>
              <app-category-image [category]="category" class="category--item--image"></app-category-image>
            </div>
            <span class="category--item--name">
              {{ category.name }}
            </span>
          </div>

          <ng-container *ngFor="let subCategory of category?.children; index as parentIndex">
            <div class="category--item" 
            *ngIf="subCategory.product_count || subCategory.children.length" 
            [ngClass]="{'active' : subCategory.active}"
            (click)="chooseCategory(category.children, parentIndex, true)"
            [routerLink]="subCategory.url">
              <app-category-image class="category--item--image" [category]="subCategory"></app-category-image>
              <span class="category--item--name" >
                {{ subCategory.name }}
              </span>
            </div>
          </ng-container>

        </div>

      </ng-container>
    </div>
</div>
