import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { BASE_URL } from '@core/lib/api.factory';
import { LiteralModel, GqlLiteralQuery } from '@core/models/literal/literal.model';
@Injectable()
export class LiteralService {
  private buffLiterals: any[];
  private buffLiteralsObj  = {};
  private literalsReady$: Observable<LiteralModel[]>;
  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string) {
    this.initLiteralsObservable();
  }

  getLiterals(): Observable<LiteralModel[]> {
    if (this.buffLiterals) {
      return of(this.buffLiterals);
    } else {
      return this.literalsReady$;
    }
  }
  getLiteralsArray(keys: string[]): Observable<any> {
    if (this.buffLiterals) {
      return of(this.findNameFromArray(keys));
    } else {
      return this.getLiterals()
        .pipe(
          map(() => this.findNameFromArray(keys))
        );
    }
  }
  // tslint:disable-next-line: ban-types
  getLiteralObj(key: string | Object): Observable<string> {
    if (this.buffLiterals) {
      return of(this.findNameFromObj(key));
    } else {
      return this.getLiterals()
        .pipe(
          map(() => this.findNameFromObj(key))
        );
    }
  }
  // tslint:disable-next-line: ban-types
  private findNameFromObj(key: string | Object): string {
    if (key instanceof Object) {
      const keys = Object.keys(key);
      return keys.map(el => this.buffLiteralsObj[el]).join('');
    } else {
      return this.buffLiteralsObj[key];
    }
  }
  private findNameFromArray(keys: string[] = []) {
    const result = {};
    keys.forEach(key => {
      result[key] = this.buffLiteralsObj[key];
    });
    return result;
  }
  private initLiteralsObservable() {
    const qglQueryString = new GqlLiteralQuery().toRest();
    this.literalsReady$ = this.http.get<{ data: any[] }>(`${this.baseUrl}/hdbk/literal?${qglQueryString}`)
        .pipe(
          map(resp => {
            this.buffLiterals = resp.data;
            this.buffLiterals.forEach(el => {
              this.buffLiteralsObj[el.code] = el.text;
            });
            return resp.data;
          }),
          share()
        );
  }
}
