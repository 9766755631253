export const SEARCH_COUNTRY = { ru: 'Поиск страны', en: 'Search country' };

export const PHONE_ERROR = {
  ru: 'Введите {n} цифр',
  en: 'Enter {n} digits',
  es: 'Ingrese {n} dígitos',
  cs: 'Zadejte {n} číslic',
  bg: 'Въведете {n} цифри',
  tr: '{N} rakam girin',
  az: '{n} rəqəmlərini daxil edin',
  hu: 'Írjon be {n} számot',
};
