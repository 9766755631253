import { LanguageService } from '@core/services/language.service';
import { Component, ChangeDetectorRef, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CityService } from '@core/services/hdbk/city.service';
import { GeoService } from '@core/services/geo.service';
import { Geo } from '@vertera-common/abstract/geo';
import { FormBuilder } from '@angular/forms';
import { CountryService } from '@core/services/hdbk/country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_CITIES } from '@core/lib/const/cities';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-geo-select',
  templateUrl: './geo-select.component.html',
  styleUrls: ['./geo-select.component.scss']
})
export class GeoSelectComponent extends Geo implements OnInit {

  constructor(
    protected formBuilder: FormBuilder,
    protected cityService: CityService,
    protected countryService: CountryService,
    protected changeDetector: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router,
    protected geoService: GeoService,
    protected languageService: LanguageService,
    @Inject(PLATFORM_ID) private platformId: any,
    protected dialog: MatDialog,
  ) {
    super(formBuilder, cityService, countryService, changeDetector, route, router, geoService, languageService,
      dialog);
  }

  openGeoMenu: boolean;
  defaultCities = [];
  showWidget: boolean;

  ngOnInit() {
    this.showWidget = isPlatformBrowser(this.platformId);
    if (this.showWidget) {
      const city = this.cityService.getCity();
      if (city && city.country && city.country.id && !this.router.url.includes('geo-lang')) {
        this.skipGeoDetection = true;
      }
      super.ngOnInit();
      this.subscribeToCitySelected();
      this.subscribeToCityChange();
      this.defaultCities = DEFAULT_CITIES[this.languageService.getLanguage()];
    }
  }

  modifyQuery(query) {
  }


  toggleGeoMenu() {
    this.openGeoMenu = ! this.openGeoMenu;
  }

  subscribeToCitySelected() {
    const sub = this.onCitySelected$.subscribe((city) => {
      this.cityService.setCity(city);
      this.router.navigate([], {
        queryParams: { country_id: city.country.id, city_id: city.id },
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        skipLocationChange: true,
      });
    });
    this.sub$.add(sub);
  }

  selectCityFromList(city) {
    this.formControls.country_id.setValue(city.country.id);
    this.country.selected = city.country;
    this.city.entitiesForDD = [city];
    this.formControls.city_id.setValue(city.id);
    this.city.selected = city;
    this.onCitySelected$.emit(city);
    this.changeDetector.detectChanges();
  }

  subscribeToCityChange() {
    const sub = this.cityService.cityChange$.subscribe((city: any) => {
      if (!this.openGeoMenu) {
        if (this.city && this.city.selected && city.id !== this.city.selected.id) {
          this.formControls.country_id.patchValue(city ? city.country.id : this.cityService.CITY.default.country.id);
          this.city.entitiesForDD = [city];
          this.formControls.city_id.setValue(city.id);
          this.city.selected = city;
          this.changeDetector.detectChanges();
        }
      }
      this.openGeoMenu = false;
    });
    this.sub$.add(sub);
  }

  detectByLocation() {
    this.cityService.removeCity();
    this.formControls.country_id.patchValue(this.cityService.CITY.default.country.id);
    this.city.entitiesForDD = [this.cityService.CITY.default];
    this.onCitySelected(this.cityService.CITY.default);
    this.runGeoDetection();
    this.changeDetector.detectChanges();
  }
}
