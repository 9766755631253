import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserHelperService } from '@core/services/user-helper.service';
import { UserModel } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { copyright } from '@core/lib/functions/copyright';
import { LanguageService } from '@core/services/language.service';
import { NgAnimateScrollService } from '@core/services';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {

  verteraHost: string;
  user: UserModel;
  private readonly sub = new Subscription();
  copyright = copyright(true);
  refundLink = environment.refundLink;
  scroller: boolean;
  lang = 'az';

  constructor(
    private userHelperSevice: UserHelperService,
    private authService: AuthService,
    private languageService: LanguageService,
    public scrollService: NgAnimateScrollService,
  ) { }

  ngOnInit() {
    this.subscribeToUser();
    const lang = this.languageService.getLanguage();
    this.verteraHost = environment.vertera.url.replace(/\.org/, `.org${ lang === 'az' ?  '' : '/' + lang }`);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  subscribeToUser() {
   const sub = this.userHelperSevice.user$.subscribe((user: UserModel) => this.user = user);
   this.sub.add(sub);
  }

  register() {
    this.authService.externalReg();
  }
  login() {
    this.authService.externalLogin();
  }
  goToUserCabinet() {
    this.authService.goToUserCabinet();
  }

  goToPartnerCabinet() {
    this.authService.redirectToPartnerLk().pipe(take(1)).subscribe();
  }

  scrollToTop() {
    this.scrollService.scrollToElement('main__header', 750);
    this.scroller = true;
    setTimeout(() => {
      this.scroller = false;
    }, 1500);
  }
}
