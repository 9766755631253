import { environment } from 'src/environments/environment';

export enum PAYMENT_SYSTEMS {
  PAYMENT_ADVCASH = 7,
  PAYMENT_PAYPAL = 11,
  PAYMENT_PAYPAL_GK = 12,
  PAYMENT_RFI = 14,
  PAYMENT_27 = 27,
  PAYMENT_KLIK_PAY = 15,
  PAYMENT_QIWI = 18,
  PAYMENT_QIWI_VISA_MC = 22,
  PAYMENT_KLIK_PAY_EU = 24,
  PAYMENT_EVO = 25,
  PAYMENT_EVOH = 26,
  PAYMENT_29 = 29,
  PAYMENT_ADVCASH_KZT = 32,
}

export interface PayModel {
  order_id: number;
  url_success: string;
  url_failed: string;
  is_one_url: number;
  payment_id: number;
}

export interface PayUrl {
  url: string;
  id: number;
  name: string;
  image?: string;
}

export class PayQuery {
  url_success: string;
  url_failed: string;
  payment_id?: number;
  constructor(
    public order_id: number,
    public langCode: string,
  ) {
    this.generateLinks();
  }

  generateLinks() {
    const rootUrl = `${environment.host}${this.langCode}/payment?success=`;
    this.url_success = `${rootUrl}true`;
    this.url_failed = `${rootUrl}false`;
  }

  get query(): PayModel {
    return {
      order_id: this.order_id,
      url_success: this.url_success,
      url_failed: this.url_failed,
      payment_id: this.payment_id,
      is_one_url: 0
    };
  }
}
