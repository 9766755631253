import { Injectable, Inject } from '@angular/core';
import { BASE_URL } from '../../lib/api.factory';
import { HttpClient } from '@angular/common/http';
import { UserBackCallQuery, UserRegModel, TokenModel, UserAddressModel, UserModel, GqlUserQuery,
   GqlAddressQuery } from '../../models/user/user.model';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RefService } from '@core/services/user/ref.service';
import { QueryLogService } from '../common/query-log.service';
import { CityService } from '../hdbk/city.service';
import { LanguageService } from '../language.service';
import { ADDRESS } from '@core/lib/const/messages';

@Injectable()
export class UserService {
  regModel: UserRegModel;
  user: UserModel;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    @Inject(BASE_URL) private baseUrl: string,
    private refService: RefService,
    private queryLogService: QueryLogService,
    private cityService: CityService,
    private languageService: LanguageService
  ) { }

  createUserCall(userBackCallQuery: UserBackCallQuery) {
    const ref = this.refService.getRef();
    if (ref) {
      userBackCallQuery.ref_code = ref;
    }
    userBackCallQuery.stat_uuid = this.queryLogService.getUUID();
    return this.http.post<any>(`${this.baseUrl}/user/back-call`, userBackCallQuery);
  }

  register(model: UserRegModel): Observable<TokenModel> {
    const ref_code = this.route.snapshot.queryParamMap.get('ref') || this.refService.getRef();
    if (ref_code) {
      model.ref_code = ref_code;
    }
    return this.http.post<{data: TokenModel}>(`${this.baseUrl}/user/reg`, model).pipe(
      map( res => res.data )
    );
  }

  setAddress(model: UserAddressModel): Observable<UserAddressModel> {
    return this.http.post<{data: UserAddressModel}>(`${this.baseUrl}/user/address?fields=id`, model).pipe(
      map( res => res.data)
    );
  }

  editAddress(model: UserAddressModel) {
    return this.http.put(`${this.baseUrl}/user/address/${model.id}`, model);
  }

  removeAddress(id: number) {
    return this.http.delete(`${this.baseUrl}/user/address/${id}`);
  }

  getAddresses(): Observable<UserAddressModel[]> {
    const query = {city_id: this.cityService.getCityId()};
    const gqlQuery = new GqlAddressQuery(query);
    return this.http.get<{data: UserAddressModel[]}>(
     `${this.baseUrl}/user/address?${gqlQuery.toRest()}`,
    ).pipe(map(response => {
      const filteredAddresses =
      response.data.filter((address) => address.build_number && address.city && address.street && address.country);
      filteredAddresses.forEach((address: UserAddressModel) => address.parsedAddress = this.parseAddress(address));
      return filteredAddresses;
    }));
  }

  getUser(): Observable<UserModel> {
    const gqlQuery = new GqlUserQuery();
    return  this.http.get<{data: UserModel}>(
      `${this.baseUrl}/user/profile?${gqlQuery.toRest()}`
    ).pipe(map(response => {
      this.user = response.data;
      return response.data;
    }));
  }

  changeUserProfile(queryModel: any): Observable<void> {
    return this.http.put<any>(`${this.baseUrl}/user/profile`, queryModel);
  }

  acceptCookies(): Observable<any> {
    return this.http.put(`${this.baseUrl}/user/accept-cookies`, {});
  }

  parseAddress(model: UserAddressModel): string {
    const language = this.languageService.getLanguage();
    let address =
    `${model.country ? model.country.name : ''}, ${model.city ? model.city.name : ''},
           ${model.street}, ${ADDRESS.house[language]} ${model.build_number}`;
    address += model.build_block ? `, ${ADDRESS.build_block[language]}  ${model.build_block}` : '';
    address += model.apart_number ? `, ${ADDRESS.flat[language]} ${model.apart_number}` : '';
    address += model.zip_code ? `, ${ADDRESS.zip_code[language]} ${model.zip_code}` : '';
    address += '.';
    return address;
  }
}
