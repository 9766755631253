import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LanguageService } from '@core/services/language.service';

@Directive({
  selector: '[appIfLang]'
})
export class IfLangDirective {
  @Input('appIfLang') set ifLang(languages: string[]) {
    const currentLanguage = this.languageService.getLanguage();
    if (languages.indexOf(currentLanguage) === -1) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private languageService: LanguageService,
  ) {
  }

}
