import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { Observable } from 'rxjs';
import { UserModel, GqlUserQuery } from '@core/models';
import { map } from 'rxjs/internal/operators/map';
import { PageResult } from '@core/models/page/page.model';
import { PageQuery } from 'src/app/catalog/abstract/catalog.model';

@Injectable({
  providedIn: 'root'
})
export class ReferralsService {

  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
  ) { }

  getUserReferrals(query: PageQuery): Observable<PageResult<UserModel[]>> {
    const gqlQuery = new GqlUserQuery(query);
    return this.http.get<{data: UserModel[]}>(
      `${this.baseUrl}/user/referrals?${gqlQuery.toRest()}`, {observe: 'response'}
    ).pipe(
        map( res => new PageResult<UserModel[]>(res) )
    )
  }

}
