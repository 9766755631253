import {
  Component, Input, Output, EventEmitter, ViewChildren, QueryList,
  ElementRef, ViewChild, HostListener, AfterViewInit, Inject,
  PLATFORM_ID, ChangeDetectorRef
} from '@angular/core';

import {
  CategoryChildModel,
  CategoryGrandParentModel,
  CategoryParentDepth3Model,
  CategoryParentModel
} from '@core/models/store/category/category.model';

import { CategoryTreeBase } from './categoryTreeBase';

import { CategoryService } from '@core/services/category/category.service';
import { CityService } from '@core/services/hdbk/city.service';

import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: [ './category.component.scss' ]
})
export class CategoryComponent extends CategoryTreeBase implements AfterViewInit{

  @Input() showCatalog: boolean;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  @Input() fixed: boolean;
  resetChoseCategory = false;

  widthscreen: number;
  oneItemWidth: number;
  allItemsWidth: number;
  itemsWrapperWidth: number;

  startTransformX: number;
  maxTansition: number;

  showPrevButton: boolean;
  showNextButton: boolean;


  @ViewChild('wrapper', {static: false}) wrapper: ElementRef;
  @ViewChild('categoryItem', {static: false}) categoryItem: ElementRef;
  @ViewChildren('categoryItem') categoryItems: QueryList<ElementRef>;


  constructor(
    protected categoryService: CategoryService,
    protected cityService: CityService,
    protected cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: any

  ) {
    super(categoryService, cityService)
  }

  ngAfterViewInit(): void {

  }


  chooseCategory(categories: CategoryGrandParentModel[] | CategoryParentModel[] | CategoryParentDepth3Model[] | CategoryChildModel[],
    index: number, lastCategory: boolean
  ) {
    this.resetChoseCategory = true;
    categories.forEach(c => c.active = false);
    categories[ index ].active = true;
    if(lastCategory){
      this.showCatalog = false;
      this.onClose.emit();
    }
  }

  dropChoseCategory() {
    this.resetChoseCategory = false;
  }

  closeCatalog() {
    this.showCatalog = false;
    this.onClose.emit();
  }



  // Width for one element
  calcOneItemWidth() {
    if (this.widthscreen >= 992 && this.widthscreen < 1200){
    this.oneItemWidth = this.categoryItem.nativeElement.offsetWidth + 8;
    } else if (this.widthscreen >= 768 && this.widthscreen < 992){
      this.oneItemWidth = this.categoryItem.nativeElement.offsetWidth + 10;
    } else if (this.widthscreen >= 576 && this.widthscreen < 768){
      this.oneItemWidth = this.categoryItem.nativeElement.offsetWidth + 10;
    } else if (this.widthscreen < 576){
      this.oneItemWidth = 0;
    } else {
      this.oneItemWidth = this.categoryItem.nativeElement.offsetWidth + 15;
    }
  }

  // Width of catalog & total length of items
  calcTotalWidths() {
    this.allItemsWidth = 0;
    this.itemsWrapperWidth = this.wrapper.nativeElement.offsetWidth;
    this.categoryItems.forEach(() => {
      this.allItemsWidth += this.oneItemWidth;
    });
    this.showNextButton = this.itemsWrapperWidth < this.allItemsWidth;
    this.startTransformX = 0;
    this.maxTansition = (this.allItemsWidth - this.itemsWrapperWidth + this.oneItemWidth);
  }

  calcWidth() {
    if (isPlatformBrowser(this.platformId)) {
      this.cd.detectChanges();
      setTimeout(() => {
        this.widthscreen = window.innerWidth;
        this.calcOneItemWidth();
        this.calcTotalWidths();
      }, 250);

    }
  }

  @HostListener('window: resize')
  recalculateValues() {
    this.calcWidth();
  }

  clickPrev() {
    this.startTransformX -= this.oneItemWidth + 80;
    this.categoryItems.forEach((item) => {
      item.nativeElement.style = `transform: translateX(-${this.startTransformX}px)`;
    });
    this.showNextButton = true;
    this.showPrevButton = this.startTransformX > 0;
  }

  clickNext() {
    this.showPrevButton = true;
    if((this.startTransformX + this.oneItemWidth*1.5) < this.maxTansition) {
      this.startTransformX += this.oneItemWidth + 80;
      this.showNextButton = true;
    } else {
      this.startTransformX = this.maxTansition;
      this.showNextButton = false
    }
    this.categoryItems.forEach((item) => {
      item.nativeElement.style.transform = `translateX(-${this.startTransformX}px)`;
    });
  }
}
