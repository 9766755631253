<div class="modal__points" *ngIf='requiredValue'>
  <div class="modal__points--text" i18n='@@notifier.cart_condition_msg_new'>
    Complete the order for <span>{{ requiredValue | number:'1.0-2' }}</span>  { requiredValue , plural,
      other {chips}
      one {chip}
      few {chips}
    } and get one more for each product for <span>free</span>
  </div>
  <div class="modal__points--round" *ngIf='showCircle'>
    <app-counter [from]='0' [to]='condition.params.current_bpv' [of]='condition.params.required_bpv' [animationTime]='500' [fontSize]=74 [circleColor]="'#087E7E'"></app-counter>
    <img class="modal__points--img" appStatic="/assets/img/cart/y-points.svg" />
  </div>
</div>
