import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthTokenService } from '@core/services/auth/auth-token.service';


@Directive({
  selector: '[appIfAuth]'
})
export class IfAuthDirective {

  @Input('appIfAuth') set reverse(reverse: any) {
    this.viewLogic(reverse);
  }

  constructor(
    private authTokenService: AuthTokenService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    this.viewLogic();
  }

  viewLogic(reverse = false) {
    const isUser = !!this.authTokenService.getAuthHeader();
    this.viewContainer.clear();
    if ((isUser && !reverse) || (!isUser && reverse)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
