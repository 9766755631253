import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BASE_URL } from '@core/lib/api.factory';
import { GqlVrMetaQuery, VrMeta } from '@core/models/hdbk/meta.model';
import { MetaService } from '@ngx-meta/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../language.service';

@Injectable({
  providedIn: 'root'
})
export class VrMetaService {

  metaTags: VrMeta[];

  constructor(private http: HttpClient,
      @Inject(BASE_URL) private baseUrl: string,
      private languageService: LanguageService,
      private router: Router,
      private readonly metaService: MetaService) {
     }

    getMeta(): Observable<VrMeta[]> {
      if (this.metaTags && this.metaTags.length > 0) {
        return of(this.metaTags);
      } else {
        const gqlQuery = new GqlVrMetaQuery();
        return this.http
          .get<{ data: VrMeta[] }>(
            `${this.baseUrl}/hdbk/seo/meta-tag?${gqlQuery.toRest()}`
          )
          .pipe(
            map(res => {
              this.metaTags = res.data;
              return res.data;
            })
          );
        }
    }

    getCurrentPageMeta(): Observable<VrMeta> {
      const language = this.languageService.getLanguage();
      return this.getMeta().pipe(map((meta) => {
        if(meta && meta.length > 0 ) {
          const url = `${environment.host}${language}${this.router.url}`.split('?')[0];
          const currentMeta = meta.find((item) => item.url === url);
          this.setMeta(currentMeta);
          return currentMeta;
        }
      }));
    }

    setMeta(currentMeta?: VrMeta) {
      if(currentMeta) {
        this.metaService.setTitle(currentMeta.title);
        this.metaService.setTag('description', currentMeta.description);
      }
    }
}

