import { Component, Input } from '@angular/core';
import { ButtonsBase } from '../buttonsBase';

@Component({
  selector: 'app-transparent-button',
  templateUrl: './transparent-button.component.html',
  styleUrls: ['./transparent-button.component.scss']
})
export class TransparentButtonComponent extends ButtonsBase {

  @Input() pt: 'pt0' | 'pt16' | '';
  @Input() height: 'h40' | 'h48' | 'h32' |'';
  @Input() cancel: boolean;
  @Input() reset: boolean;
  @Input() breadcrumbs: boolean;
  @Input() options: boolean;
  @Input() white: boolean;
  @Input() pink: boolean;

  get _class(): string {
    const list = ['button', this.pt, this.height];
    return list.join(' ');
  }

  constructor() {
    super();
  }

}
