import { Type } from '@core/models/geo/city.model';
import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export class BannerQuery {
  constructor(
    public location_const = 1,
    public type_const = 1
  ) {
  }
  country_id: number;
}

export interface BannerPicture {
  id: number;
  uri: string;
  media_size: Type;
}

export interface BannerUrl {
  url: string;
  params: {[key: string]: string }[];
}

export interface ParsedImages {
  small?: string;
  large?: string;
  retina?: string;
}

export interface Banner extends ParsedImages {
  id: number;
  title: string;
  link_href: string;
  url: BannerUrl;
  position: number;
  location: Type;
  type: Type;
  status: Type;
  pictures: BannerPicture[];
}

export const gqlBannerPicture = `
  id
  uri
  media_size
`;

export const gqlBannerFields = `{
  id
  title
  link_href
  url
  position
  location
  type
  status
  pictures {
    ${gqlBannerPicture}
  }
}`;

export class GqlBannerQuery extends GqlQuery {
  fields = gqlBannerFields;
}
