import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BASE_URL } from '@core/lib/api.factory';
import { UserConfirmQuery, UserValidateQuery } from '@core/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserConfirmService {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    @Inject(BASE_URL) private baseUrl: string
  ) { }

  send(query: UserConfirmQuery): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/user/confirm-send`, query);
  }

  validate(query: UserValidateQuery): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/user/confirm-validate`, query);
  }
}
