import { Inject, Injectable } from '@angular/core';
import { BASE_URL } from '@core/lib/api.factory';
import { HttpClient } from '@angular/common/http';
import { Banner, BannerQuery, GqlBannerQuery, BannerUrl } from '@core/models/common/banner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CityService } from '../hdbk/city.service';
import { LanguageService } from '../language.service';

export const mediaSizeIdToKey = {
  1: 'small',
  3: 'large',
  5: 'retina',
};

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  mediaSizeIdToKey = mediaSizeIdToKey;

  constructor(
    @Inject(BASE_URL) private baseUrl: string,
    private http: HttpClient,
    private cityService: CityService,
    private languageService: LanguageService
  ) {
  }

  getBanners(query: BannerQuery): Observable<Banner[]> {
    const city = this.cityService.getCity();
    if (city && city.country) {
      query.country_id = city.country.id;
    }
    const gqlQuery = new GqlBannerQuery(query);
    return this.http.get<{data: Banner[]}>(`${this.baseUrl}/common/banner?${gqlQuery.toRest()}`).pipe(
      map( res => this.processPictures(res.data)),
      map((banners) => {
        this.modifyLanguagePrefix(banners);
        return banners.filter(banner => banner.pictures);
      })
    );
  }

  processPictures(banners: Banner[]): Banner[] {
    const sortedBanners: Banner[] = [];
    if (banners) {
      banners.forEach( banner => {
        if (banner.pictures) {
          banner.pictures.forEach( picture => {
            banner[this.mediaSizeIdToKey[picture.media_size.id]] = picture.uri;
          });
        }
        sortedBanners[banner.position - 1] = banner;
      });
      return sortedBanners.filter( banner => !!banner);
    } else {
      return [];
    }
  }

  modifyLanguagePrefix(banners: Banner[]) {
    banners.map(banner => this.modifyBannerLink(banner.url));
  }

  modifyBannerLink(bannerUrl: BannerUrl) {
    if(bannerUrl && bannerUrl.url && bannerUrl.url.startsWith('http')) {
      const currentLanguage: string = this.languageService.getLanguage();
      let hasLanguageCode: boolean = false;
      // if a link includes a language prefix, for example https://landings.dev.vertera.org/ru/thalassolab
      this.languageService.supportedLanguages.forEach((lang: string) =>  {
        if(bannerUrl.url.includes(`/${lang}/`)) {
          bannerUrl.url = bannerUrl.url.replace(`/${lang}/`, `/${currentLanguage}/`);
          hasLanguageCode = true;
        }
      });
      // if a link doesn't include a language prefix, for example http://verteracollagen.com/
      if (!hasLanguageCode) {
        if(!bannerUrl.url.endsWith('/')) {
          bannerUrl.url = `${bannerUrl.url}/`
        }
        bannerUrl.url = `${bannerUrl.url}${currentLanguage}/`;
      }
    }
  }
}
