import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { trimQueryParams } from '@core/lib/functions/trim-query';
import { CategoryService } from '@core/services/category/category.service';
import { ProductService } from '../product/product.service';
import { LanguageService } from '../language.service';
import { CategoryModel } from '@core/models/store/category/category.model';

export const catalogText = {
  en: 'Catalogue',
  ru: 'Каталог',
  cs: 'Katalog',
  bg: 'Каталог',
  es: 'Catálogo',
  de: 'Warenkatalog',
  tr: 'Catalog',
  az: 'Kataloq',
  hu: 'Katalógus',
};

export const newsText = {
  en: 'News',
  ru: 'Новости',
  cs: 'News',
  bg: 'News',
  es: 'News',
  de: 'News',
  tr: 'News',
  az: 'Xəbərlər'
}


@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService implements OnDestroy {
  readonly catalogText = catalogText;
  readonly blogText = newsText;
  private readonly subscriptions: Subscription = new Subscription();
  private breadCrumps: { name: string, url: string, queryParams?: {} }[];
  private prevUrl: string;
  isActive$ = new BehaviorSubject<boolean>(false);
  breadCrumbsSubject = new BehaviorSubject([]);

  constructor(
    private router: Router,
    private categoryService: CategoryService,
    private productService: ProductService,
    private languageService: LanguageService,
  ) {
    this.subscribeToRoute();
    this.subscribeToInstances();
    this.buildBreadcrumbs();
  }

  buildBreadcrumbs() {
    this.breadCrumps = [];
    const path = this.getPath();
    const urlSegments = path.split('/');

    const firstSegment = urlSegments[1];
    const secondSegment = urlSegments[2];

    if (urlSegments.length < 1) {
      return;
    }

    if (firstSegment) {
      this.addShopsCatalogBreadCrumb();
    }

    for (const section of ['catalog', 'news']) {
      if ('catalog' === firstSegment) {
        if (secondSegment !== 'all') {
          this.buildCategoryBreadcrumb(secondSegment);
        }
      } else if ('news' === firstSegment && 'news' === section) {
        this.addBlogBreadCrumb();
      } else {
        this.breadCrumps = [];
      }
    }
    this.breadCrumbsSubject.next(this.breadCrumps);
    this.prevUrl = path;
  }

  private subscribeToRoute() {
    const sub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        filter(() => {
          const path = this.getPath();
          this.isActive$.next(true);
          return path !== this.prevUrl;
        })
      )
      .subscribe(() => {
        this.buildBreadcrumbs();
      });
    this.subscriptions.add(sub);
  }

  private subscribeToInstances() {
    const sub = this.productService.product$.subscribe(product => {
      this.addShopsCatalogBreadCrumb();
      if (product.categories) {
        this.breadCrumps.push({
          name: product.categories[0].name,
          url: `/catalog/${product.categories[0].slug}`
        });
      }
    });
    this.subscriptions.add(sub);
  }

  private getPath(): string {
    return trimQueryParams(this.router.url);
  }

  private addShopsCatalogBreadCrumb() {
    const lang = this.languageService.getLanguage();
    this.breadCrumps.push({name: this.catalogText[lang], url: '/catalog/all'});
  }

  private addBlogBreadCrumb() {
    const lang = this.languageService.getLanguage();
    this.breadCrumps.push({name: this.blogText[lang], url: '/news'});
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private buildCategoryBreadcrumb(secondSegment) {
    const sub = this.categoryService.categories$.subscribe( categories => {
      const category = categories.find( _category => _category.slug === secondSegment);
      if (category.depth && category.depth > 1) {
        const parentCategory = this.findParentCategory(categories, category);
        if (parentCategory) {
          this.breadCrumps[1] = {name: parentCategory.name, url: `/catalog/${parentCategory.slug}`};
          this.breadCrumps[2] = {name: category.name, url: `/catalog/${secondSegment}`};
        }
      } else {
        this.breadCrumps[1] = {name: category.name, url: `/catalog/${secondSegment}`};
      }
      this.isActive$.next(true);
    });
    this.subscriptions.add(sub);
  }

  private findParentCategory(categories: CategoryModel[], childCategory: CategoryModel): CategoryModel  {
    const index = categories.findIndex( _category => _category.slug === childCategory.slug);
    const prevCategories = categories.slice(0, index);
    const parentCategory = prevCategories.reverse().find(_category => _category.depth === childCategory.depth - 1);
    return parentCategory;
  }
}


