import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotificationComponent } from './notification.component';
import { NotifierComponent } from './notifier/notifier.component';
import { VerteraCommonModule } from '@vertera-common';
import { IconModule } from '../icon/icon.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { NotifierStockInfoComponent } from './notifier-stock-info/notifier-stock-info.component';
import { CounterModule } from 'angular-circle-counter';


@NgModule({
  imports: [
    CommonModule,
    VerteraCommonModule,
    IconModule,
    ButtonsModule,
    RouterModule,
    CounterModule,
  ],
  exports: [NotificationComponent, NotifierComponent],
  declarations: [NotificationComponent, NotifierComponent, NotifierStockInfoComponent]
})
export class NotificationModule { }
