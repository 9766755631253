import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppTokenService {
  private appToken;
  constructor() { }

  setToken(token: string) {
    this.appToken = token;
  }
  getToken(): string {
    return this.appToken;
  }
}
