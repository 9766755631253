import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from '@core/services';
import { Subscription } from 'rxjs';
import { UserHelperService } from '@core/services/user-helper.service';
import { LanguageService } from '@core/services/language.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit, OnDestroy {

  verterahost: string;
  readonly sub$: Subscription = new Subscription();

  @HostBinding('class.show')
  showCookies: boolean;

  constructor(
    private userService: UserService,
    private userHelperService: UserHelperService,
    private languageService: LanguageService,
    private router: Router,
  ) { }

  ngOnInit() {
    const lang = this.languageService.getLanguage();
    this.verterahost = environment.vertera.url.replace(/\.org/, `.org${ lang === 'az' ?  '' : '/' + lang }`);
    this.sub$.add(
      this.userHelperService.acceptCookies$
        .subscribe( accepted => {
          this.showCookies = !accepted;
          this.subscribeToRouter();
        })
    );
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }

  accept() {
    this.sub$.add(
      this.userService.acceptCookies().subscribe(() => {
        if (Object.entries(this.userService.user).length !== 0) {
          this.userService.user.is_accepted_cookies = true;
        }
        this.close();
      }, err => {
        if (Object.entries(this.userService.user).length !== 0) {
          this.userService.user.is_accepted_cookies = true;
        }
        this.close();
      })
    );
    this.close();
  }

  close() {
    this.showCookies = false;
  }

  subscribeToRouter() {
    const sub = this.router.events.pipe(
      filter( event => event instanceof NavigationEnd),
      filter( event => !(this.userService.user && this.userService.user.is_accepted_cookies))
    ).subscribe(() => {
      this.showCookies = true;
    });
    this.sub$.add(sub);
  }
}
