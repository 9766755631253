export class QueryLog {
  uuid: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  yandex_metric_client_id?: string;
  google_analytics_client_id?: string;
}
