import { Injectable, Inject } from '@angular/core';
import { BASE_URL } from '@core/lib/api.factory';
import { HttpClient } from '@angular/common/http';
import { VRStorageQuery, GqlVRStorageQuery, VRStorage, DeliveryPointsQuery, GqlDeliveryPointsQuery, DeliveryPoint } from '@core/models/storage/storage.model';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppStorage } from '@core/storage';
import { CurrencyService } from '../currency.service';

@Injectable()
export class StorageService {

  readonly storageKey: string = `${environment.appName}_storage`;

  constructor(
    private http: HttpClient,
    private currencyService: CurrencyService,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(AppStorage) protected appStorage: Storage,
  ) { }

   getStorages(query: VRStorageQuery): Observable<VRStorage[]>  {
    const gqlQuery = new GqlVRStorageQuery(query);
    return  this.http
     .get<{ data: VRStorage[] }>(
       `${this.baseUrl}/store/storage?${gqlQuery.toRest()}`
     )
     .pipe(
       map(res => {
         return res.data;
       }),
       catchError(err => throwError(err))
     );

   }

   getDeliveryPoints(query: DeliveryPointsQuery): Observable<DeliveryPoint[]>  {
    const gqlQuery = new GqlDeliveryPointsQuery(query);
    return this.http.get<{data: DeliveryPoint[]}>(`${this.baseUrl}/store/cart/delivery-point?${gqlQuery.toRest()}`)
      .pipe(
        map(res => {
          return res.data;
        })
      );
  }

   setStorage(storage: VRStorage) {
     this.appStorage.setItem(this.storageKey, JSON.stringify(storage));
   }

   getStorage(): VRStorage {
     const storage = this.appStorage.getItem(this.storageKey);
     return storage ? JSON.parse(storage) : null;
   }

   removeStorage() {
     this.appStorage.removeItem(this.storageKey);
   }
}
