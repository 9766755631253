export const LANG_ID_MAPS = {
  en: 'en-US',
  ru: 'ru-RU',
  es: 'es-ES',
  cs: 'cs-CZ',
  bg: 'bg-BG',
  de: 'de-DE',
  fr: 'fr-FR',
  el: 'gr-GR',
  it: 'it-IT',
  kg: 'ky-KY',
  kz: 'kz-KZ',
  pl: 'pl-PL',
  pt: 'pr-PR',
  ro: 'rm-RM',
  rs: 'sb-SB',
  tr: 'tr-TR',
  az: 'az-AZ'
};
