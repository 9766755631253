import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

interface StoragePhones {
  phone: string;
}

export class GeoPoint {
  id: number;
  address: string;
  lat: string;
  lng: string;
  icon?: string;
  price?: number;
  days_from?: number;
  days_to?: number;
  currency_code?: string;
}
export class VRStorage extends GeoPoint {
  name: string;
  phones: StoragePhones[];
  city: {
    id: number;
    name: string;
  };
  country: {
    id: number;
    name: string;
  };
  icon?: string;
}

export class VRStorageQuery {
  country_id: number;
  city_id: number;
  product_ids: string;
  product_slugs: string;
  currency_codes: string;
  is_public: number;
  phones: StoragePhones[];
}

export class DeliveryPointsQuery {
  services: string;
  country_id: number;
  city_id: number;
}

export class DeliveryPoint extends GeoPoint {
  service: string;
  storage_id: number;
  currency_code: string;
  price: number;
  days_from: number;
  days_to: number;
}


export const gqlDeliveryPointFields  = `{
  id
  service
  storage_id
  address
  lng
  lat
  currency_code
  price
  days_from
  days_to
}`;



export const gqlVRStorageFields = `{
  id
  name
  address
  lat
  lng
  phones {
    phone
  }
  city {
    id
    name
  }
  country {
    id
    name
  }
}`;

export class GqlVRStorageQuery extends GqlQuery {
  fields = gqlVRStorageFields;
}

export class GqlDeliveryPointsQuery extends GqlQuery {
  fields = gqlDeliveryPointFields;
}

