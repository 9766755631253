/* tslint:disable */
export class Guid {
    static newGuid(): string {
        const res = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const test = c == 'x';
            const r = Math.random() * 16 | 0, v = test ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
        return res;
    }
}
