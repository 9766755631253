import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export class LiteralModel {
  code: string;
  text: string;
}

/**
 * ------- gql models --------
 */

export const gqlLiteralFields = `{
  code
  text
}`;

export class GqlLiteralQuery extends GqlQuery {
  fields = gqlLiteralFields;
}
