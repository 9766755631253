import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIcon } from './svg-icon.component';


@NgModule({
  declarations: [SvgIcon],
  imports: [
    CommonModule
  ],
  exports: [
    SvgIcon,
  ]
})
export class IconModule { }
