import { Component, Input } from '@angular/core';
import { ButtonsBase } from '../buttonsBase';

@Component({
  selector: 'app-green-button',
  templateUrl: './green-button.component.html',
  styleUrls: ['./green-button.component.scss']
})
export class GreenButtonComponent extends ButtonsBase {

  @Input() pt: 'pt0' | 'pt16';
  @Input() font: 'bold' | 'normal';
  @Input() red: boolean;
  @Input() white: boolean;
  @Input() height: 'h40' | 'h48' | 'h52' | 'h56' | 'h32' | 'h44' | '';
  @Input() round: boolean;

  get _class(): string {
    const list = ['button', this.pt, this.font, this.red, this.height];
    return list.join(' ');
  }

  constructor() {
    super();
  }
}
