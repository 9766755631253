import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TextDialogComponent } from './text-dialog.component';



@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
  ],
  declarations: [TextDialogComponent],
  entryComponents: [TextDialogComponent],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ]
})
export class TextDialogModule { }
