import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appVrTabFocus]'
})
export class VrTabFocusDirective {

  @HostListener('keydown.Tab', ['$event']) onTabPressed(event) {
    event.stopPropagation();
  }

  constructor() { }

}
