import { Pipe, PipeTransform } from '@angular/core';
import { LiteralService } from '@core/services/hdbk/literal.service';

@Pipe({
  name: 'vrLiteral'
})
export class LiteralPipe implements PipeTransform {
  constructor(private literalService: LiteralService) {
  }
  // tslint:disable-next-line: ban-types
  transform(value: string | Object): Promise<string> {
    return this.literalService.getLiteralObj(value).toPromise();
  }
}
