import { Component, Input } from '@angular/core';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styles: [`
    .icon {
      display: inline-block;
      vertical-align: top;
      fill: currentColor;
    }
  `]
})
// tslint:disable-next-line:component-class-suffix
export class SvgIcon {
  /**
   * Base path for SVG sprite
   */
  public readonly basePath = '/assets/icon';

  /**
   * Icon size
   */
  // tslint:disable-next-line:no-input-rename
  @Input('size') size = 16;

  /**
   * Symbol ID value from SVG sprite
   */
  // tslint:disable-next-line:no-input-rename
  @Input('name') name: string;

  constructor() {}
}
