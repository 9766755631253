import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AppTokenService } from '@core/services/app-token.service';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

@Injectable()
export class AppTokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appTokenService = this.injector.get(AppTokenService);
    const token = appTokenService.getToken();
    if (token) {
      const multiplyHeaders = req.headers.set('X-App-Token', token);
      const authReq = req.clone({ headers: multiplyHeaders });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }
}
