import { RedirectService } from '../redirectService';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RefService } from '../user/ref.service';
import { LanguageService } from '../language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthTokenService } from './auth-token.service';
import { AuthSessionService } from './auth-session.service';
import { LANG_ID_MAPS } from '@core/lib/const/langs';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { CityService } from '../hdbk/city.service';
import { StockNotifyService } from 'src/app/catalog/services/stock-notify.service';
import { AuthMethodService } from './auth-method.service';

@Injectable()
export class AuthService extends RedirectService {
  private tokenKey = `${environment.appName}_token`;
  constructor(
    public router: Router,
    private cityService: CityService,
    @Inject(PLATFORM_ID) public platform: any,
    private route: ActivatedRoute,
    private refService: RefService,
    private languageService: LanguageService,
    private http: HttpClient,
    private authTokenService: AuthTokenService,
    private authSessionService: AuthSessionService,
    @Inject(RESPONSE) public response: any,
    private stockNotifyService: StockNotifyService,
    private authMethodService: AuthMethodService
  ) {
    super(router, platform, response);
  }

  get loginLink(): string {
    const link = this.authMethodService.getIsUseNewEca() ? environment.newECA.login: environment.authLink.login;
    return this.getAuthOrLoginLink(link);
  }

  get logOutLink(): string {
    return this.authMethodService.getIsUseNewEca() ? environment.newECA.idlogout : environment.authLink.idlogout;
  }

  get regLink(): string {
    const link = this.authMethodService.getIsUseNewEca() ? environment.newECA.registration : environment.authLink.registration;
    return this.getAuthOrLoginLink(link);
  }

  get globalLink(): string {
    return `${environment.global.url}${this.languageService.getLanguage()}/?${this.getCountryIdQuery()}&${this.getRefQuery()}`
  }

  externalReg() {
    this.redirectToUrl(this.regLink, true);
  }

  externalLogin() {
    this.redirectToUrl(this.loginLink, true);
  }

  goToUserCabinet() {
    const lang = this.languageService.getLanguage();
    const lkLangLink = environment.authLink.lk.replace(/\.org\//, `.org/${ lang === 'az' ?  '' : lang + '/' }`);
    this.redirectToUrl(lkLangLink, true);
  }

  logOut() {
    if (isPlatformBrowser(this.platform)) {
      this.stockNotifyService.clearStockToken();
      setTimeout(() => {
        this.redirectToUrl(this.logOutLink, true);
      });
    }
  }

  getAuthOrLoginLink(link: string) {
    let redirect = isPlatformBrowser(this.platform) ? location.pathname.replace('external/authorization', '') : '/';
    if (/^(\/\w{2})\//.test(redirect)) {
      redirect = redirect.replace(/^\/\w{2}/, '');
    }
    const urlParams = new HttpParams({fromObject: this.route.snapshot.queryParams}).toString();
    const encodedRedirect = encodeURIComponent(`${redirect}?${urlParams}`);
    const encodedReturn = encodeURIComponent(`${environment.host}?redirect=${encodedRedirect}`);
    return `${link}${this.refService.getRefUrlToECA()}${this.getCountryQuery()}${this.getLanguageQuery()}&return=${encodedReturn}`;
  }

  signOs(session_key: string, redirect: string): Observable<any> {
    return this.http
      .post(`${environment.baseUri}/user/auth-os?field=token`, {
        session_key,
        token: this.authSessionService.getSessionHeader()
      })
      .pipe(
        map(data => {
          // tslint:disable-next-line: no-string-literal
          const dataObj = data['data'];
          if (dataObj && dataObj.token) {
            // DO: сделано на уровне server.ts, но на всякий оставлен тут.
            if (!isPlatformBrowser(this.platform)) {
              const monthMs = 2678400000; // 31 * 24 * 60 * 60 * 1000
              const expires = new Date(Date.now() + monthMs); // 1 month
              if (this.response) {
                this.response.cookie(this.tokenKey, dataObj.token, {
                  expires
                });
              } else {
                console.warn('this._response undefined');
              }
            } else {
              this.authTokenService.setAuthHeader(dataObj.token);
            }
            this.redirectToUrl(redirect);
          }
          return dataObj;
        })
      );
  }

  signByTempToken(token: string, redirect: string): Observable<any> {
    return this.http
    .post(`${environment.baseUri}/user/auth-by-temp-token?field=token`, {
      token,
    })
    .pipe(
      map(data => {
        // tslint:disable-next-line: no-string-literal
        const dataObj = data['data'];
        if (dataObj && dataObj.token) {
          // DO: сделано на уровне server.ts, но на всякий оставлен тут.
          if (!isPlatformBrowser(this.platform)) {
            const monthMs = 2678400000; // 31 * 24 * 60 * 60 * 1000
            const expires = new Date(Date.now() + monthMs); // 1 month
            if (this.response) {
              this.response.cookie(this.tokenKey, dataObj.token, {
                expires
              });
            } else {
              console.warn('this._response undefined');
            }
          } else {
            this.authTokenService.setAuthHeader(dataObj.token);
          }
          this.redirectToUrl(redirect);
        }
        return dataObj;
      })
    );
  }

  redirectToPartnerLk(): Observable<any> {
    return this.http
    .post(`${environment.baseUri}/user/create-token-auth-on-os?fields=token,link`, {})
    .pipe(
      map(({data}: any) => {
        // tslint:disable-next-line: no-string-literal
        if(data && data.link) {
          this.redirectToUrl(data.link, true);
        }
      })
    );
  }

  clearCookieAndExit(destination: string = '') {
    const url =
      destination === 'registration' ? environment.authLink.registration : '/'; // : '/geo-lang'
    if (!isPlatformBrowser(this.platform)) {
      if (this.response) {
        this.response.cookie(this.tokenKey, '', { expires: new Date(0) });
        this.response.cookie(this.cityService.cityKey, '', { expires: new Date(0) });
      } else {
        console.warn('this._response undefined');
      }
      this.redirectToUrl(url);
    } else {
      this.authTokenService.clearAuthHeaderValue();
      setTimeout(() => {
        this.redirectToUrl(url, true);
      }, 300);
    }
  }

  private getLanguageQuery(): string {
    return `&language=${LANG_ID_MAPS[this.languageService.getLanguage()]}`;
  }

  private getCountryQuery(): string {
    return `&countryCode=${this.cityService.getCountryCode()}`;
  }

  private getCountryIdQuery(): string {
    return `country_id=${this.cityService.getCountryId()}`;
  }

  private getRefQuery(): string {
    const ref = this.refService.getRef();
    return ref? `ref=${ref}` : '';
  }


}
