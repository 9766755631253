<button class="menu__mobile--button"
    *ngIf="!resetChoseCategory"
    (click)="toggleCatalogList()"
    routerLink="/catalog">
  <svg-icon name="ic--arrow-bold" *ngIf="showCatalog" [size]="12"></svg-icon>
  <span i18n="@@common.catalogue" *ngIf="!showCatalog">Catalogue</span>
  <span i18n="@@common.back_to_menu" *ngIf="showCatalog">Back to menu</span>
</button>
<div class="catalog" [ngClass]="{'opened': showCatalog }">
  <div class="category">
    <div class="category__list main" [ngClass]="{'show' : !resetChoseCategory}">
      <div class="category--item" *ngFor="let category of categories; index as grandParentIndex;"
        (click)="category?.children.length > 0 ? choseCategory(categories, grandParentIndex) : selectCategoryCloseMenu()"
        [class.active]="category.active && resetChoseCategory" [routerLink]="category.url">
          <app-category-image class="category--item--image" [category]="category"></app-category-image>
          <span class="category--item--name">{{ category.name }}</span>
      </div>
    </div>
    <ng-container *ngFor="let category of categories; index as grandParentIndex;">

      <!-- 2 lvl category -->
      <div class="category__list second"
        [ngClass]="{'show' : category.active && resetChoseCategory}">
        <div class="category--item back-to-parent" *ngIf="category.active && category?.children.length > 0" (click)="backToParentCategory()">
          <div class="category--item--image">
            <svg-icon name="ic--arrow-bold" [size]="24"></svg-icon>
            <span class="back--txt" i18n="@@common.back_to_catalog">Back to catalog</span>
            <picture>

              <img [src]="category?.pictures && category?.pictures[1].thumbs.c" alt="{{ category.name }}">
            </picture>
          </div>
          <span class="category--item--name">
            {{ category.name }}
          </span>
        </div>

        <ng-container *ngFor="let subCategory of category?.children; index as parentIndex">
          <div class="category--item" 
          *ngIf="subCategory.product_count || subCategory.children.length" 
          [ngClass]="{'active' : subCategory.active}"
          (click)="choseCategory(category.children, parentIndex)"
          [routerLink]="subCategory.url">
            <app-category-image class="category--item--image" [category]="subCategory"></app-category-image>
            <span class="category--item--name" >
              {{ subCategory.name }}
            </span>
          </div>
        </ng-container>
      </div>
      
    </ng-container>
  </div>
</div>
