import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'static'
})
export class StaticPipe implements PipeTransform {
  transform(value: string): string {
    return environment.staticUrl + value;
  }
}
