
import gql from 'graphql-tag';
import { gqlToRest } from './gqlToRest';
import { getQuerysFields } from './querybuilder';


/**
 * GraphQL base class
 */
export abstract class GqlQuery {
  query: string;
  abstract fields: string;

  constructor(query: any = null) {
    if (query) {
      this.query = getQuerysFields(query).join(',');
    }
  }

  toRest() {
    return gqlToRest(
      gql(this.query
        ? `{ entities(${this.query}) ${this.fields} }`
        : `{ entity ${this.fields} }`)
    );
  }
}


/**
 * --- Example ---
 *
 * Init class:
 * GqlEntityQuery extends GqlQuery {
 *   fields = `{
 *     id
 *     name
 *   }`
 * }
 *
 * Get entities:
 * const gqlQuery = new GqlEntityQuery(query);
 * this.http.get(`/url?${gqlQuery.toRest()}`)
 *
 * Get single entity:
 * const gqlQuery = new GqlEntityQuery();
 * this.http.get(`/url/${id}?${gqlQuery.toRest()}`)
 *
 */
