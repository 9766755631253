import { Component, EventEmitter, Output } from '@angular/core';

import { CategoryTreeBase } from '../categoryTreeBase';

import {
  CategoryChildModel,
  CategoryGrandParentModel,
  CategoryParentDepth3Model,
  CategoryParentModel
} from '@core/models/store/category/category.model';
import { CategoryService } from '@core/services/category/category.service';
import { CityService } from '@core/services/hdbk/city.service';

@Component({
  selector: 'app-mobile-category',
  templateUrl: './mobile-category.component.html',
  styleUrls: ['./mobile-category.component.scss']
})
export class MobileCategoryComponent extends CategoryTreeBase {

  resetChoseCategory: boolean;

  showCatalog: boolean;

  constructor(
    protected categoryService: CategoryService,
    protected cityService: CityService,
  ) {
    super(categoryService, cityService);
  }

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleCatalog: EventEmitter<any> = new EventEmitter<any>();


  // Actions in menu

  choseCategory(categories: CategoryGrandParentModel[] | CategoryParentModel[] | CategoryParentDepth3Model[] | CategoryChildModel[],
    index: number
  ) {
    this.resetChoseCategory = true;
    categories.forEach(c => c.active = false);
    categories[ index ].active = true;
    this.onClose.emit();
    this.showCatalog = true;
  }

  backToParentCategory() {
    this.resetChoseCategory = false;
    this.showCatalog = true;
  }

  selectCategoryCloseMenu() {
    this.onClose.emit();
  }

  toggleCatalogList() {
    this.toggleCatalog.emit();
    this.showCatalog = !this.showCatalog;
  }

  calcWidth(): void {

  }
}
