import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';
import { PageQuery } from 'src/app/catalog/abstract/catalog.model';
import { Product, gqlProductMinFields } from '../product/product.model';

export class FavoritesQuery extends PageQuery {
  search: string;
  country_id: number;
}

export class FavoritesAddQuery {
  product_ids: string;
}

export class FavoritesModel {
  id: number;
  created: string;
  product: Product;
}

export const gqlFavoritesField = `{
  id
  created
  product {
    ${gqlProductMinFields}
  }
}`;

export class GqlFavoritesQuery extends GqlQuery {
  fields = gqlFavoritesField;
}
