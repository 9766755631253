<div class="header" [ngClass]="{'fixed': stickyHeader, 'opened': showCatalogMenu }">
  <div class="container">
    <div class="header__top" [class.show]="showAside">
      <div class="menu__left">
        <div class="menu--item">
          <app-geo-select *ngIf="widthscreen > 991"></app-geo-select>
        </div>
        <div class="menu--item"><a routerLink="/delivery" target="_blank" i18n>Delivery</a></div>
        <div class="menu--item"><a routerLink="/about" i18n="@@common.about_us">About us</a></div>
        <!--
        <div class="menu--item"><a href="https://old.dev.vertera.org/geography" target="_blank">География</a></div>
        -->
        <div class="menu--item"><a class="menu--item_link" [href]="globalLink" target="_blank"><svg-icon [size]="16" name="ic--global"></svg-icon><span>Vertera Global</span></a></div>
      </div>
      <div class="menu__right">
        <!--
        <div class="menu--item">
          <a routerLink="/faq" i18n="@@common.faq-menu">Questions and answers</a>
        </div>
        -->
        <div class="menu--item phone">994 51 331 42 91</div>
        <app-language-switcher [supportedLanguages]="['ru','az']"></app-language-switcher>
    </div>
      <div class="menu__mobile">
        <div class="menu__mobile--header">
          <app-geo-select *ngIf="widthscreen <= 991"></app-geo-select>
          <app-language-switcher [supportedLanguages]="['ru', 'az']"></app-language-switcher>
        </div>
        <!-- TODO: вывести общее число монет у пользователя -->
        <!-- div class="menu__mobile--header">
          <span>Монеты</span>
          <span class="menu__mobile--header-points" *appIfAuth>
            0.99 <img appStatic="/assets/img/cart/y-points.svg" />
          </span>
        </div -->
        <div class="menu__mobile--body tiny-scroll">
          <app-mobile-category
            *ngIf="widthscreen  < 576"
            (toggleCatalog)="toggleCatalogMenu()"
            (onClose)="closeCatalogMenu()">
          </app-mobile-category>
          <ul *ngIf="!showCatalogMenu">
            <li>
              <a routerLink="/delivery" i18n>Delivery</a>
            </li>
            <li>
              <a routerLink="/about" i18n="@@common.about_us">About us</a>
            </li>
            <li>
              <a class="menu--item_link" [href]="globalLink" target="_blank"><svg-icon [size]="16" name="ic--global"></svg-icon><span>Vertera Global</span></a>
            </li>
            <!--
            <li>
              <a href="https://os.verteraorganic.com/lkc.php?logout=yes" target="_blank" i18n>Partner's office</a>
            </li>
            -->
            <!--
            <li>
              <a href="https://old.dev.vertera.org/geography" target="_blank">География</a>
            </li>
            -->
          </ul>
        </div>
        <div class="menu__mobile--footer" *ngIf="!showCatalogMenu">
          <span i18n>Multichannel phone</span>
          <a href="tel:+88007072539">8 (800) 707-25-39</a>
        </div>
      </div>
    </div>
  </div>
  <div class="header__bottom">
    <div class="container">
      <app-transparent-button class="header__hamburger" (click)="toggleAside()" [class.show]="showAside">
        <svg-icon [name]="showAside ? 'control--close': 'control--hamburger-dark'" [size]="24"></svg-icon>
      </app-transparent-button>
      <div id='scroll_anchor'></div>
      <a routerLink="/" class="header__logo" [class.login]="user && !isUserLoading" [class.new_year]="newYear">
        <img appStatic="/assets/img/logo.svg" alt="Vertera" class="header__logo--image">
        <img appStatic="/assets/img/logo-sm.png" alt="Vertera" class="header__logo--image sm">
<!--        <img appStatic="/assets/img/new-year/garland_xs.svg" alt=""  class="header__logo&#45;&#45;image&#45;&#45;ny sm">-->
<!--        <img appStatic="/assets/img/new-year/garland.svg" alt=""  class="header__logo&#45;&#45;image&#45;&#45;ny">-->
      </a>
      <app-green-button class="header__button--catalog"
                        *ngIf="widthscreen >= 576"
                        (click)="toggleCatalogMenu()"
                        [ngClass]="{'login' : user && !isUserLoading, 'opened' : showCatalogMenu}"
                        [routerLink]="showCatalogMenu?'#':'/catalog'"
                        [red]="showCatalogMenu ? 'red': ''">
        <svg-icon [name]="showCatalogMenu ? 'control--close' : 'control--hamburger'"
                  class="header__button--catalog-icon" [size]="showCatalogMenu ? 16 : 18"></svg-icon>
        <span i18n="@@common.catalogue" class="{{ language }}">Catalogue</span>
      </app-green-button>
      <app-search-form [class.login]="user" class="{{ language }}"></app-search-form>
      <div class="content-loader" *ngIf='isUserLoading'></div>
      <div *ngIf="!user && !isUserLoading" class="header__bottom--menu no-login {{ language }}">
        <div class="menu--item">
          <a routerLink="/cart">
            <svg-icon name="control--cart" size="24"></svg-icon>
            <span i18n>Cart</span>
          </a>
          <span class="menu--item-count red">
            <span>{{cartViewService.totalAmount}}</span>
          </span>
        </div>
        <div class="menu--item login">
          <a (click)="login()">
            <svg-icon name="control--lock" size="24"></svg-icon>
            <span i18n="@@sign_in">Sign in</span>
          </a>
        </div>
        <a class="reg__link" target="_blank"
           (click)='register()' i18n="@@sign_up">
          Sign up
        </a>
      </div>
      <div *ngIf="user && !isUserLoading" class="header__bottom--menu user-menu">

        <div class="menu--item stock">
          <a (click)="toggleStockPanel()">
            <svg-icon name="ic--giftt" size="24"></svg-icon>
            <span i18n>Stocks</span>
            <span class="menu--item-count red">
                <span>{{stocks.length}}</span>
              </span>
          </a>
        </div>

        <div class="menu--item dropdown" [class._opened]="_opened">
          <a class="dropdown-toggle" id="userMenuButton" (click)="toggleUserMenu()">
            <svg-icon name="ic--profile" size="24"></svg-icon>
            <span i18n>Cabinet</span>
          </a>
          <ul class="dropdown-menu custom-dropdown" aria-labelledby="userMenuButton">
            <li class="dropdown-item">
              <a i18n='@@lk.profile' class="link" routerLink='/account/profile'>
                <span>Profile</span>
              </a>
            </li>
            <li class="dropdown-item">
              <a i18n='@@lk.orders_history' class="link" routerLink='/account/orders-history'>
                <span>Order history</span>
              </a>
            </li>
            <li class="dropdown-item">
              <a i18n='@@lk.ref_link' class="link" routerLink="/account/referral">
                <span>Referral link</span>
              </a>
            </li>
            <li class="dropdown-item">
              <a class="link" (click)='logout()' i18n="@@log_out">
                Log out
              </a>
            </li>
          </ul>
        </div>
        <div class="menu--item favorites">
          <a routerLink='/account/favorites'>
            <svg-icon name="control--favorites" size="24"></svg-icon>
            <span i18n>Favorite</span>
            <span class="menu--item-count red">
              <span>{{favoritesCount}}</span>
            </span>
          </a>
        </div>
        <div class="menu--item cart" routerLink="/cart">
          <a>
            <svg-icon name="control--cart" size="24"></svg-icon>
            <span i18n>Cart</span>
          </a>
          <span class="menu--item-count red">
            <span>{{cartViewService.totalAmount}}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-category (clickOutside)="onClickedOutsideCatalogue($event)" [fixed]="stickyHeader" *ngIf="widthscreen >= 576" [showCatalog]="showCatalogMenu"
                (onClose)="toggleCatalogMenu()"></app-category>
</div>
<div class="container">
  <app-breadcrumbs></app-breadcrumbs>
</div>

<app-stock-panel
  [stocks]='stocks'
  [lang]='language'
  (clickOutside)="onClickedOutsideStock($event)"
  [class.sticky]="sticky"
  [class.opened]="open_panel"
  (onChanged)="onChanged()">
</app-stock-panel>

