import { getSearchQuerys } from '@core/lib/helpers/graph-ql/querybuilder';
import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';
import { CountryModel } from '../hdbk/country.model';

export interface Type {
  id: number;
  name: string;
}

export class CityModel {
  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
  id: number;
  name: string;
  country?: CountryModel;
  short_name?: string;
  toString(): string {
    return this.name;
  }
}
export class CityQueryModel {
  id?: string;
  search?: string;
  country_id?: number;
  getQuery() {
    return getSearchQuerys(this).join('&');
  }
}

export class CitySuggestionQueryModel {
  search?: string;
  country_id?: number;
}

export const gqlCitySuggestionFields =  `{
  id,
  long_name,
  short_name
}`;

export class GqlCitySuggestionQuery extends GqlQuery {
  fields = gqlCitySuggestionFields;
}
