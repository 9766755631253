export const CITIES = {
  297: {
    id: 5476412,
    name: 'London',
    country: {
      id: 297,
      name: 'Great Britain',
      cities: [],
      alpha2: 'GB'
    },
  },
  115: {
    id: 5833829,
    name: 'Vienna',
    country: {
      id: 115,
      name: 'Austria',
      alpha2: 'AT',
      cities: [],
    }
  },
  132: {
    id: 5515011,
    name: 'Brussels',
    country: {
      id: 132,
      name: 'Belgium',
      alpha2: 'BE',
      cities: [],
    }
  },
  135: {
    id: 5476176,
    name: 'Sofia',
    country: {
      id: 135,
      name: 'Bulgaria',
      alpha2: 'BG',
      cities: [],
    }
  },
  331: {
    id: 6577084,
    name: 'Prague',
    country: {
      id: 331,
      name: 'Chekhiya',
      alpha2: 'CZ',
      cities: [],
    }
  },
  327: {
    id: 5479435,
    name: 'Zagreb',
    country: {
      id: 327,
      name: 'Croatia',
      alpha2: 'HR',
      cities: [],
    }
  },
  169: {
    id: 5477733,
    name: 'Copenhagen',
    country: {
      id: 169,
      name: 'Denmark',
      alpha2: 'DK',
      cities: [],
    }
  },
  342: {
    id: 5479703,
    name: 'Tallinn',
    country: {
      id: 342,
      name: 'Estonia',
      alpha2: 'EE',
      cities: [],
    }
  },
  323: {
    id: 5479304,
    name: 'Paris',
    country: {
      id: 323,
      name: 'France',
      alpha2: 'FR',
      cities: [],
    }
  },
  160: {
    id: 6753986,
    name: 'Berlin',
    country: {
      id: 160,
      name: 'Germany',
      alpha2: 'DE',
      cities: [],
    }
  },
  167: {
    id: 5477660,
    name: 'Athens',
    country: {
      id: 167,
      name: 'Greece',
      alpha2: 'GR',
      cities: [],
    }
  },
  146: {
    id: 5476684,
    name: 'Budapest',
    country: {
      id: 146,
      name: 'Hungary',
      alpha2: 'HU',
      cities: [],
    }
  },
  184: {
    id: 5477783,
    name: 'Dublin',
    country: {
      id: 184,
      name: 'Ireland',
      alpha2: 'IE',
      cities: [],
    }
  },
  187: {
    id: 6760508,
    name: 'Rome',
    country: {
      id: 187,
      name: 'Italy',
      alpha2: 'IT',
      cities: [],
    }
  },
  209: {
    id: 5478071,
    name: 'Riga',
    country: {
      id: 209,
      name: 'Latvia',
      alpha2: 'LV',
      cities: [],
    }
  },
  215: {
    id: 5478083,
    name: 'Vilnius',
    country: {
      id: 215,
      name: 'Lithuania',
      alpha2: 'LT',
      cities: [],
    }
  },
  216: {
    id: 5478142,
    name: 'Luxembourg City',
    country: {
      id: 216,
      name: 'Luxembourg',
      alpha2: 'LU',
      cities: [],
    }
  },
  244: {
    id: 6366518,
    name: 'Amsterdam',
    country: {
      id: 244,
      name: 'Netherlands',
      alpha2: 'NL',
      cities: [],
    }
  },
  270: {
    id: 6427785,
    name: 'Warsaw',
    country: {
      id: 270,
      name: 'Poland',
      alpha2: 'PL',
      cities: [],
    }
  },
  271: {
    id: 6744419,
    name: 'Lisbon',
    country: {
      id: 271,
      name: 'Portugal',
      alpha2: 'PT',
      cities: [],
    }
  },
  276: {
    id: 5478664,
    name: 'Bucharest',
    country: {
      id: 276,
      name: 'Romania',
      alpha2: 'RO',
      cities: [],
    }
  },
  333: {
    id: 5479475,
    name: 'Bern',
    country: {
      id: 333,
      name: 'Shveytsariya',
      alpha2: 'CH',
      cities: [],
    }
  },
  295: {
    id: 5478743,
    name: 'Bratislava',
    country: {
      id: 295,
      name: 'Slovakia',
      alpha2: 'SK',
      cities: [],
    }
  },
  296: {
    id: 6671024,
    name: 'Ljubljana',
    country: {
      id: 296,
      name: 'Slovenia',
      alpha2: 'SI',
      cities: [],
    }
  },
  186: {
    id: 6101005,
    name: 'Madrid',
    country: {
      id: 186,
      name: 'Spain',
      alpha2: 'ES',
      cities: [],
    }
  },
  334: {
    id: 5479653,
    name: 'Stockholm',
    country: {
      id: 334,
      name: 'Sweden',
      alpha2: 'SE',
      cities: [],
    }
  },
  321: {
    id: 5479051,
    name: 'Helsinki',
    country: {
      id: 321,
      name: 'Finland',
      alpha2: 'FI',
      cities: [],
    }
  },
  398: {
    id: 5469474,
    name: 'Нур-Султан',
    country: {
      id: 398,
      name: 'Казахстан',
      alpha2: 'KZ',
      cities: [],
    }
  },
  48: {
    id: 5469677,
    name: 'Бишкек',
    country: {
      id: 48,
      name: 'Киргизия',
      alpha2: 'KG',
      cities: [],
    }
  },
  16: {
    id: 5469475,
    name: 'Минск',
    country: {
      id: 16,
      name: 'Беларусь',
      alpha2: 'BY',
      cities: [],
    }
  },
};
