import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { flatMap, tap } from 'rxjs/operators';
import { DeliveryViewService } from 'src/app/cart/services/delivery-view.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    isAuth$ = new BehaviorSubject<boolean>(false);
    constructor(private injector: Injector) {
        // this.subscribeAuth();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.injector.get(AuthTokenService);
        // a temporary order token is used to create an order on a background registration for an unathorized user,
        // and for all requests before an order creation,
        // after an order creation it gets deleted
        const temporaryOrderToken = this.injector.get(DeliveryViewService).orderToken;
        // const auth = this.injector.get(AuthService);
        return token.getAuthorizationHeaderValue().pipe(
            flatMap(authHeader => {
                if (authHeader || temporaryOrderToken) {
                    this.isAuth$.next(true);
                    const multiplyHeaders = req.headers.set('Authorization', temporaryOrderToken || authHeader);
                    const authReq = req.clone({ headers: multiplyHeaders });
                    return next.handle(authReq).pipe(tap(() => {}, (err: any) => {
                        if (err instanceof HttpErrorResponse) {
                          if (err.status !== 401) {
                           return;
                          }
                         token.clearAuthHeaderValue();
                        }}));
                } else {
                  return next.handle(req);
                }
            }));
    }
    // IF Auth in another browser tabs
    /*
    private subscribeAuth() {
        this.isAuth$.pipe(distinctUntilChanged()).subscribe(isAuth => {
            if (isAuth) {
                this.store.dispatch(getUserProfile());
            }
        });
    }*/
}
