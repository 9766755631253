import { Directive, HostListener, AfterViewInit, Output, EventEmitter, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { NgxIntlTelInputComponent, CountryISO } from 'ngx-intl-tel-input';
import { LanguageService } from '@core/services/language.service';
import { PHONE_ERROR } from '@core/lib/translations';

@Directive({
  selector: '[appNgxTelInputCustomPlaceholder]'
})
export class NgxTelInputCustomPlaceholderDirective implements AfterViewInit, OnInit {
  countryISO = CountryISO;

  constructor(private phonePlugin: NgxIntlTelInputComponent, private languageService: LanguageService,
    @Inject(PLATFORM_ID) public platform: any) { }

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onValidateMessage = new EventEmitter<string>();
  @Input() appNgxTelInputCustomPlaceholder;
  validInputLength: number;
  localNumber: string;
  phone: string;

  @HostListener('click', ['$event']) onClick($event) {
    this.parsePlaceholder();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.parsePlaceholder();
    }, 100);
  }

  parsePlaceholder() {
    if (!this.phonePlugin.selectedCountry.placeHolder.includes('X')) {
      const countryCode = `+${this.phonePlugin.selectedCountry.dialCode}`;
      const originalPlaceHolder = this.phonePlugin.selectedCountry.placeHolder;
      this.localNumber = originalPlaceHolder.replace(`${countryCode} `, '');
      this.validInputLength = this.localNumber.replace(/[^0-9]/g, '').length;
      this.phonePlugin.selectedCountry.placeHolder = `${countryCode} ${this.localNumber.replace(new RegExp(`[0-9]`, 'g'), 'X')}`;
      this.generateErrorText();
    } else {
      this.generateErrorText();
    }
  }

  private generateErrorText() {
    if (this.phonePlugin.value && this.phonePlugin.value.toString().length === this.validInputLength) {
      this.onValidateMessage.emit('');
    } else {
      const errorText = PHONE_ERROR[this.languageService.getLanguage()].replace('{n}', this.validInputLength);
      this.onValidateMessage.emit(errorText);
    }
  }
}
