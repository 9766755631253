import { ElementRef, Input, ViewChild } from '@angular/core';

export class ButtonsBase {

  @Input() role = 'submit';
  @Input() target: '_blank' | '_self' | '_parent' | '_top' = '_self';
  @Input() href: string;
  @Input() download?: string;
  @Input() transform: 'uppercase' | 'normal';
  active: boolean;
  _disabled = false;

  @Input() set disabled(v: any) {
    if (v === 'true') {
      this._disabled = true;
    } else if (v === 'false') {
      this._disabled = false;
    } else {
      this._disabled = v;
    }
  }

  get disabled(): any {
    return this._disabled;
  }

  @ViewChild('btn', {static: false}) _btn: ElementRef;

  constructor() {
  }

  toggleActive(): void {
    this.active = !this.active;
  }
}
