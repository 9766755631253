export const MANGO_LANG_TO_ID = {
    mk: 12291,
    en: 12273,
    cs: 12283,
    bg: 12281,
    ru: 12275,
    de: 12287,
    tr: 10401,
    es: 12285,
    sr: 12293,
    hu: 12295,
};