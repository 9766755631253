import { HttpResponse } from '@angular/common/http';

export class PaginationResponse {
  public currentPage: number;
  public countPage: number;
  public perPage: number;
  public totalCount: number;

  constructor(response: HttpResponse<any>) {
    this.currentPage = +response.headers.get('X-Pagination-Current-Page');
    this.countPage = +response.headers.get('X-Pagination-Page-Count');
    this.perPage = +response.headers.get('X-Pagination-Per__Page');
    this.totalCount = +response.headers.get('X-Pagination-Total-Count');
  }
}

export class PageResult<T> {
  data: T;
  pagination: PaginationResponse;
  constructor(response: HttpResponse<{data: T}>) {
      if (response) {
          this.pagination = new PaginationResponse(response);
          this.data = response.body.data;
      }
  }
}
