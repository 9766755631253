import { Injectable } from '@angular/core';
import { RouteInterface } from '../../../catalog/catalog.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LocationService {
  set next(v: RouteInterface[]) {
    this._currentRoute$.next(v);
  }
  get currentRoute(): RouteInterface[] {
    return this._currentRoute$.value;
  }
  get routeChanges(): Observable<any> {
    return this._currentRoute$.asObservable();
  }
  readonly mainPage: RouteInterface = {
    name: 'Главная',
    url: '/',
    children: [
    ]
  };
  private readonly _currentRoute$ = new BehaviorSubject<RouteInterface[]>([]);
}
