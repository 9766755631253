import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { GqlArticlesQuery, Article, ArticleQuery, GqlArticleQuery } from '@core/models';
import { map, tap } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { mediaSizeIdToKey } from '../common/banner.service';
import { PageResult } from '@core/models/page/page.model';
import { CityService } from '../hdbk/city.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  public articles$ = new ReplaySubject<PageResult<Article[]>>(1);
  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string,
    private cityService: CityService,
  ) {
  }

  getArticles(query: ArticleQuery): Observable<PageResult<Article[]>> {
    query.country_id = this.cityService.getCountryId();
    const gqlQuery = new GqlArticlesQuery(query);
    return this.http
      .get<any>(`${this.baseUrl}/content/article?${gqlQuery.toRest()}`, { observe: 'response' })
      .pipe(
        map( res => new PageResult<Article[]>(res)),
        map( res => this.processImages(res)),
        tap( res => {
          this.articles$.next(res);
          return res;
        })
      );
  }

  getArticle(slug: string): Observable<Article> {
    const gqlQuery = new GqlArticleQuery({ country_id: this.cityService.getCountryId()} );
    return this.http
      .get<{data: Article}>(`${this.baseUrl}/content/article/${slug}?${gqlQuery.toRest()}`)
      .pipe(
        map( res => this.processImage(res.data))
      );
  }

  processImages(res: PageResult<Article[]> | any) {
    res.data = res.data.map( article => this.processImage(article));
    return res;
  }

  processImage(article: Article) {
    if (article.main_image) {
      article.main_image.forEach(image => {
        article[mediaSizeIdToKey[image.media_size.id]] = image.uri;
      });
    }

    if (article.image) {
      article.clientImages = [];
      article.image.forEach(image => {
        article.clientImages[mediaSizeIdToKey[image.media_size.id]] = image.uri;
      });
    }
    return article;
  }

}
