<div class="lang dropdown dropdown-angle" *ngIf="currentLanguage" [class._opened]="_opened">
  <div class="link dropdown-toggle" data-toggle="dropdown" id="langMenuButton" (click)="toggleLang()">
    <span class="lang-flag" appStatic="/assets/icons/countries/flag-{{currentLanguage.code}}.svg"></span>
    <span class="lang-name">{{ currentLanguage.name }}</span>
  </div>
  <ul class="dropdown-menu" aria-labelledby="langMenuButton">
    <li class="dropdown-item" *ngFor="let lang of languages">
      <a class="link" href="/{{lang.code}}{{url}}">
        <span class="lang-flag" appStatic="/assets/icons/countries/flag-{{lang.code}}.svg"></span>
        <span class="lang-name">{{lang.name}}</span>
      </a>
    </li>
  </ul>
</div>
