import { Injectable, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth/auth.service';
import { UserHelperService } from '@core/services/user-helper.service';
import { UserService } from '@core/services';
import { AuthMethodService } from '@core/services/auth/auth-method.service';

@Injectable()
export class AuthGuard implements CanActivate {
  token: boolean;
  constructor(
    private auth: AuthService,
    @Inject(PLATFORM_ID) public platform: any,
    private userHelper: UserHelperService,
    private userSerivce: UserService,
    private injector: Injector,
    private authMethodService: AuthMethodService
  ) {
    this.token = !!this.injector.get(AuthTokenService).getAuthHeader();
  }

  canActivate(): Observable<boolean> | boolean {
    return this.authMethodService.fetch().pipe(switchMap(() => {
      if (!this.userSerivce.user) {
        return this.userSerivce.getUser().pipe(
          map(user => this.userGuardMap(user))
        );
      } else {
        return this.userHelper.user$.pipe(
          map(user => this.userGuardMap(user))
        );
      }
    }))

  }

  userGuardMap(user) {
    let isCanActivate = true;
    if (!this.token) {
      isCanActivate = false;
      this.auth.externalLogin();
    }
    if (!user) {
      isCanActivate = false;
      this.auth.externalLogin();
    }
    return isCanActivate;
  }
}
