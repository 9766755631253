import { ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { SearchService } from '@core/services/product/search.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription, of } from 'rxjs';
import { SearchModel, SearchQuery } from '@core/models/product/search.model';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CityService } from '@core/services/hdbk/city.service';


@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormComponent implements OnInit {

  showSearchForm: boolean;
  readonly sub$ = new Subscription();
  query: SearchQuery;
  searchResult: SearchModel[];

  public search$ = new EventEmitter<string>();
  private allowSearch$ = new Subject<boolean>();
  isLoading: boolean;

  @ViewChild('select', {static: false}) select: NgSelectComponent;

  constructor(
    private searchService: SearchService,
    public route: ActivatedRoute,
    public router: Router,
    // public filterService: FilterService,
    public changeDetector: ChangeDetectorRef,
    private cityService: CityService,
    private cd: ChangeDetectorRef,
  ) {
    this.sub$.add(
      router.events.pipe(
        filter(e => e instanceof NavigationEnd)
      ).subscribe(() => {
        const q = this.route.snapshot.queryParams.search;
        if (!q) {
          // this.inputElRef.nativeElement.value = '';
        }
      }));
  }

  ngOnInit() {
    this.query = new SearchQuery();
    this.subscribeToSearch();
    this.subscribeToRoute();
  }

  search(event) {
    if (!event.term) {
      this.searchResult = [];
    }
  }

  clear() {
    if (this.searchResult && this.searchResult.length === 1) {
      this.router.navigate(['/product', this.searchResult[0].slug]);
    }
    this.searchResult = [];
  }

  toggleSearch() {
    if (window.innerWidth > 991 && !this.showSearchForm) {
      this.onSearchCatalog();
      return;
    }
    this.showSearchForm = !this.showSearchForm;
    if (this.showSearchForm === true) {
      this.select.focus();
    }
  }

  subscribeToSearch() {
    const sub = this.search$.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      filter((term) => !!term),
      switchMap((term: string) => {
        if(term.length > 2) {
          this.isLoading = true;
          this.query.query = term;
          const city = this.cityService.getCity();
          if (city && city.id && city.country.id) {
            this.query.city_id = city.id;
            this.query.country_id = city.country.id;
          }
          this.query.sort = 'score';
          this.query.page = 1;
          this.query.per__page = 10;
          return this.searchService.search(this.query);
        } else {
          return of(null);
        }
      }),
      filter(res => !!res),
    ).subscribe(res => {
      this.searchResult = res;
      this.isLoading = false;
      this.changeDetector.detectChanges();
    });
    this.sub$.add(sub);
  }

  onSearchProduct(event) {
    if (event) {
      this.showSearchForm = false;
      this.router.navigate(['/product', event.slug]);
    }
  }

  onSearchCatalog() {
    this.cd.detectChanges();
    const queryParams = {
      search: typeof this.query.query === 'string' ? this.query.query : this.query.query.title,
    };
    this.router.navigate(['/catalog', 'all'], {queryParams, queryParamsHandling: 'merge'});
    this.showSearchForm = false;
  }

  private subscribeToRoute() {
    this.sub$.add(
      this.route.queryParams.subscribe((params: any) => {
        if (params.search) {
          this.query.query = params.search;
        }
      })
    );
  }
}
