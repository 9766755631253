import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { CategoryModel } from '@core/models/store/category/category.model';

@Component({
  selector: 'app-category-image',
  templateUrl: './category-image.component.html',
  styleUrls: ['./category-image.component.scss']
})
export class CategoryImageComponent implements OnInit  {

  @Input() category: CategoryModel;
  imageSrc: string;


  @HostListener('window:resize')
  onResize() {
    if(isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 992) {
        this.imageSrc = this.category.pictures && this.category.pictures[1].thumbs.c;
      } else {
        this.imageSrc = this.category.pictures && this.category.pictures[0].thumbs.c;
      }
    }
  }

  constructor(@Inject(PLATFORM_ID) private platformId: any,) { }

  ngOnInit() {
    this.onResize();
  }

}
