import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { RouteInterface } from '../../../../catalog/catalog.component';
import { BreadcrumbsService } from '@core/services/location/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  constructor(
    private breadCrumbsService: BreadcrumbsService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  route$: Observable<RouteInterface[]>;
  showBreadcrumbsWithoutCategories: boolean;
  sub: Subscription = new Subscription();

  ngOnInit() {
    const sub = this.breadCrumbsService.isActive$.subscribe( (isActive: boolean) => {
      this.showBreadcrumbsWithoutCategories = isActive;
      this.route$ = this.breadCrumbsService.breadCrumbsSubject;
      this.changeDetector.detectChanges();
    });
    this.sub.add(sub);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}

