import { Injectable, Injector } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { AuthSessionService } from './../services/auth/auth-session.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const session = this.injector.get(AuthSessionService);
        return session.getSessionHeaderValue().pipe(
            flatMap(header => {
              if (header) {
                const multiplyHeaders = req.headers.set('X-Session-Token', header);
                const authReq = req.clone({ headers: multiplyHeaders });

                return next.handle(authReq);
              } else {
                return next.handle(req);
              }
            }));
    }
}
