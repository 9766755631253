import { Inject, Injectable } from '@angular/core';
import { AuthTokenService } from '@core/services/auth/auth-token.service';
import { AppStorage } from '@core/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockNotifyService {
  private tokenKey = `${environment.appName}_stock_token`;

  constructor(private authTokenService: AuthTokenService,
    @Inject(AppStorage) private appStorage: Storage,) { }

    isShowStockPanel(): boolean {
      const currentToken = this.authTokenService.getAuthHeader();
      const prevToken = this.appStorage.getItem(this.tokenKey);
      if (prevToken !== currentToken) {
          this.appStorage.setItem(this.tokenKey, currentToken);
          return true;
      }
    }

    clearStockToken() {
      this.appStorage.removeItem(this.tokenKey);
    }
}
