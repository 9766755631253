import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
  selector: '[appVrGoBack]'
})
export class VrGoBackDirective {

  constructor(public location: Location) { }

  @HostListener('click') onClick() {
    this.location.back();
  }
}
