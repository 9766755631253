import { Inject, Injectable, PLATFORM_ID, Optional } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(
    public router: Router,
    @Inject(PLATFORM_ID) public platform: any,
    @Optional() @Inject(RESPONSE) public response: any
  ) {
  }

  redirectToUrl(url: string = '/', changeLocation = false) {
    if (isPlatformServer(this.platform)) {
      this.response.redirect(302, url);
    } else {
      if (changeLocation) {
        location.href = url;
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }
}
