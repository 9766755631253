import { environment } from 'src/environments/environment';
import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';


@Directive({
  selector: '[appStatic]'
})
export class StaticDirective {
  _staticSrcset?: string;
  @Input() set staticSrcset(url: string){
    this._staticSrcset = url;
    if (this._staticSrcset) {
      const newSrcset = this._staticSrcset
        .split(', ')
        .map((src: string) => src.replace('/assets', `${environment.staticUrl}/assets`))
        .join(', ');
      this.el.nativeElement.srcset = newSrcset;
    }
  };


  @Input() set appStatic(url: string) {
    const link = `${environment.staticUrl}${url}`;

    switch (this.el.nativeElement.tagName) {
      case 'IMG': {
        this.el.nativeElement.src = link;
        break;
      }
      case 'A': {
        this.el.nativeElement.href = link;
        break;
      }
      case 'SOURCE': {
        break;
      }
      case 'SPAN': case 'DIV': {
        this.renderer.setAttribute(this.el.nativeElement, 'style', `background-image: url('${link}')`);
        break;
      }
      default: {
        this.el.nativeElement.src = link;
      }
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) { }
}
