import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import { Subscription } from 'rxjs';
import { filter, throttleTime, distinctUntilChanged } from 'rxjs/operators';
import { trimQueryParams } from '@core/lib/functions/trim-query';
import { NgAnimateScrollService } from '@core/services/ng-animate-scroll.service';

@Injectable()
export class ScrollToTopService {
  prevParams: Params = {};
  prevUrl: string;
  sub: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
    private scrollService: NgAnimateScrollService,
    private location: PlatformLocation
  ) { }

  init() {
    this.subscribeToRouter();
    this.subscribeToBrowserBack();
  }

  destroy() {
    this.sub.unsubscribe();
  }

  private subscribeToRouter() {
    const sub = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        throttleTime(1000),
      )
      .subscribe((event: NavigationEnd) => {
        const pureUrl = trimQueryParams(this.router.url);
        this.scrollToTop();
        this.prevParams = this.route.snapshot.queryParams;
        this.prevUrl = pureUrl;
      });
    this.sub.add(sub);
  }

  scrollToTop() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.route.snapshot.queryParams.keep || this.route.snapshot.queryParams.preventScrollToTop) {
      return;
    }
    if (!this.changed()) {
      return;
    }
    this.scrollService.scrollToElement('scroll_anchor', 300);
  }

  changed(): boolean {
    if (this.prevUrl && trimQueryParams(this.router.url) !== this.prevUrl) {
      return true;
    }
    const params: Params = this.route.snapshot.queryParams;
    const keys = Object.keys(params);
    const changed = keys.filter(key => key !== 'keep').filter(key => params[key] !== this.prevParams[key]);
    return changed.length > 0;
  }
  subscribeToBrowserBack() {
    this.location.onPopState(() => {
      this.scrollService.scrollToTop(800);
    });
  }
}
