import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TextDialogComponent } from '@core/dialogs/text-dialog/text-dialog.component';


export interface ConfirmDialigInterface {
  question: string;
  accept?: string;
  deny?: string;
  type?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent  implements OnInit {
  text: string;
  accept: string;
  deny: string;
  type: string;

  constructor(
    public dialogRef: MatDialogRef<TextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialigInterface,
  ) { }

  ngOnInit() {
    this.text = this.data.question;
    this.accept = this.data.accept;
    this.deny = this.data.deny;
    this.type = this.data.type;
  }

  close(isConfirmed?: boolean) {
    this.dialogRef.close(isConfirmed);
  }

}
