<ng-select #select
           placeholder="What do you want to find?" i18n-placeholder
           [class.show]="showSearchForm"
           [items]="searchResult"
           (close)="clear()"
           (search)="search($event)"
           (change)="onSearchProduct($event)"
           [loading]="isLoading"
           [typeahead]="search$"
           [clearable]="true"
           [(ngModel)]="query.query"
           bindLabel="title"
           typeToSearchText="Type to search" i18n-typeToSearchText
           notFoundText="No items found" i18n-notFoundText="@@ng_select_not_found"
           class="search--input">
</ng-select>
<div class="search--bg" [class.show]="showSearchForm"></div>

<app-green-button class="search--button" pt="pt16" role="button" [class.md]="showSearchForm" (click)="onSearchCatalog()">
  <svg-icon name="control--search" [size]="19"  class="search--button-icon"></svg-icon>
</app-green-button>
<svg-icon [name]="showSearchForm ? 'control--close' : 'control--search-dark'"
          [size]="showSearchForm ? '18' : '22'" class="search--icon"
          (click)="toggleSearch()"
          [class.opened]="showSearchForm">
</svg-icon>
