import { Injectable, Inject } from '@angular/core';
import { AppStorage } from '@core/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(@Inject(AppStorage) private appStorage: Storage) { }

  public setCurrency(currency: string) {
    if (currency) {
      this.appStorage.setItem(`${environment.appName}_currency`, currency);
    }
  }
  public getCurrency(): string {
   return this.appStorage.getItem(`${environment.appName}_currency`) || 'RUB';
  }
}
