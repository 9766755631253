import { Component, OnInit, Inject, ChangeDetectorRef, HostListener, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppTokenService } from '@core/services/app-token.service';
import { AppStorage } from '@core/storage';
import { LanguageService } from '@core/services/language.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit, AfterViewInit {

  language: string;
  scrollHeader: boolean;
  widthscreen: number;

  constructor(
    private appTokenService: AppTokenService,
    @Inject(AppStorage) private appStorage: Storage,
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef,
  ) {
    this.appTokenService.setToken(environment.vertera.token);
    this.appStorage.setItem(`${environment.appName}_app_token`, environment.vertera.token);
  }

  ngOnInit() {
    this.scrollHeader = false;
    this.language = this.languageService.getLanguage();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add( this.language );
  }

  ngAfterViewInit() {
    this.calcWidth();
    this.onScroll();
    this.cd.detectChanges();
  }

  @HostListener('window: resize')
  calcWidth() {
   this.widthscreen = window.innerWidth;
  }

  @HostListener('window: scroll')
  onScroll() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.widthscreen < 992){
        this.scrollHeader = window.pageYOffset >= 72;
      } else {
        this.scrollHeader = window.pageYOffset >= 121;
      }
    }
  }
}
