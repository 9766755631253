import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export class CityQuery {
  search: string;
  country_id: number;
  has_storage: 0 | 1;
  product_ids?: string;
  constructor() {
    this.has_storage = 1;
  }
}


export const gqlCityFields = `{
  id
  name
  country
}`;

export class GqlCityQuery extends GqlQuery {
  fields = gqlCityFields;
}
