import { BannerPicture, gqlBannerPicture, ParsedImages } from '../common/banner';
import { CountryModel, gqlCountryFields } from '../hdbk/country.model';
import { Product, gqlProductMinFields } from '../product/product.model';
import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';
import { PageQuery } from 'src/app/catalog/abstract/catalog.model';


export interface Article extends ParsedImages {
  media_size: any;
  uri: any;
  id: number;
  slug: string;
  title: string;
  published_at: string;
  intro_text: string;
  end_text: string;
  main_image: BannerPicture[];
  image: BannerPicture[];
  countries: CountryModel[];
  related_articles: Article[];
  products: Product[];
  seo_title: string;
  seo_description: string;
  seo_keywords: string;
  clientImages: any;
}

export class ArticleQuery extends PageQuery {
  country_id: number;
  constructor(per__page = 9, page = 1) {
    super(page, per__page);
  }
}

export const gqlMinArticleFields = `
  id
  slug
  title
  published_at
  main_image { ${gqlBannerPicture} }
`;

export const gqlArticleFields = `{
  ${gqlMinArticleFields}
  intro_text
  end_text
  image {
    ${gqlBannerPicture}
  }
  countries ${gqlCountryFields}
  related_articles {
    ${gqlMinArticleFields}
  }
  products {
    ${gqlProductMinFields}
  }
  seo_title
  seo_description
  seo_keywords
}`;

export class GqlArticlesQuery extends GqlQuery {
  fields = `{ ${gqlMinArticleFields} }`;
}

export class GqlArticleQuery extends GqlQuery {
  fields = gqlArticleFields;
}
