<ng-container *ngIf="href">
  <a class="no-link" [href]="href" [target]="target">
    <ng-container *ngTemplateOutlet="btnTemplate"></ng-container>
  </a>
</ng-container>
<ng-container *ngIf="!href">
  <ng-container *ngTemplateOutlet="btnTemplate"></ng-container>
</ng-container>
<ng-template #btnTemplate>
  <button #btn
          [type]="role"
          [disabled]="disabled"
          tabindex="-1"
          class="{{ _class }}"
          [ngClass]="{'red': red, 'white': white}">
    <ng-content></ng-content>
  </button>
</ng-template>
