import { GqlQuery } from '@core/lib/helpers/graph-ql/graph-ql';

export class VrMeta {
    url: string;
    title: string;
    description: string;
}

export const gqlVrMetaFields = `{
    url
    title
    description
  }`;

  export class GqlVrMetaQuery extends GqlQuery {
    fields = gqlVrMetaFields;
  }