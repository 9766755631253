import { Component, OnInit, OnDestroy } from '@angular/core';
import { CartCondition } from '@core/models';
import { Subscription } from 'rxjs';
import { CartViewService } from 'src/app/cart/services/cart-view.service';

@Component({
  selector: 'app-notifier-stock-info',
  templateUrl: './notifier-stock-info.component.html',
  styleUrls: ['./notifier-stock-info.component.scss', '../notifier/notifier.component.scss']
})
export class NotifierStockInfoComponent implements OnInit, OnDestroy {
  requiredValue: number;
  condition: CartCondition;
  sub: Subscription = new Subscription();
  showCircle: boolean;

  constructor(private cartViewService: CartViewService) { }

  ngOnInit() {
    this.subscribeToStockConditions();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  subscribeToStockConditions() {
    const sub = this.cartViewService.stockConditionsUpdate$.subscribe((conditions: CartCondition[]) => {
      if(conditions && conditions.length > 0) {
        this.showCircle = false;
        if( conditions[0].params.required_bpv > conditions[0].params.current_bpv ) {
          this.condition = conditions[0];
          this.requiredValue = conditions[0].params.required_bpv - conditions[0].params.current_bpv;
          setTimeout(() => {
            this.showCircle = true;
          }, 1);
        } else {
          this.requiredValue = 0;
        }

      }
    });
    this.sub.add(sub);
  }
}
