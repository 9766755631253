export function getSearchQuerys(model): string[] {
    const keys = Object.keys(model).sort();
    const params = [];
    keys.forEach(el => {
        if (Array.isArray(model[el])) {
            params.push(`${el}=${model[el].join(',')}`);
        } else if (el && model[el]) {
            params.push(`${el}=${model[el]}`);
        }
    });
    return params;
}
export function getQuerysFields(model): string[] {
    const keys = Object.keys(model).sort();
    const params = [];
    keys.forEach(el => {
        // if value is a string
        if ( Object.prototype.toString.call(model[el]) === '[object String]' ) {
            // adds escape symbol \ before " character in a string,  graphql-tag can correctly parse it then
            model[el] = model[el].replace(/\"/g, '\\"');
        }
        if (!Array.isArray(model[el])) {
            // const nameEl = el.replace('per__page', 'per__page'); // чтобы спарсить в норм параметр
            params.push(`${el}:"${model[el]}"`);
        } else {
            params.push(`${el}:"${model[el].join(',')}"`);
        }
    });
    return params;
}
