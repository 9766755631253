<ul class="breadcrumbs" *ngIf="(route$ | async).length">
  <li>
    <a routerLink="/" i18n>Main page</a>
  </li>
  <li *ngFor="let level of route$ | async; let last = last">
    <a routerLink="{{ level.url }}">{{ level.name }}</a>
  </li>
</ul>
<!--
<app-transparent-button [cancel]="true" [breadcrumbs]="true" *ngIf="(route$ | async).length">
 <img appStatic="/assets/icons/ic/arrow-left.svg" class="icon">
  <span>Каталог</span>
</app-transparent-button>
-->
