export const DEFAULT_CITIES: any = {
  en: [
    { id: 5469360, name: 'Moscow', country: { id: 113, name: 'Russia', alpha2: 'RU' } },
    { id: 6577084 , name: 'Prague', country: { id: 331, name: 'Czech Republic', alpha2: 'CZ' } },
    { id: 5476176, name: 'Sofia', country: { id: 21, name: 'Bulgaria', alpha2: 'BG'}}
  ],
  ru: [
    { id: 5469360, name: 'Москва', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 5469361, name: 'Санкт-Петербург', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 4, name: 'Новосибирск', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 3, name: 'Екатеринбург', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 66, name: 'Нижний Новгород', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 53, name: 'Краснодар', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 104,  name: 'Челябинск', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 47, name: 'Кемерово', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 95, name: 'Тюмень', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 54, name: 'Красноярск', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 88, name: 'Казань', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
    { id: 72, name: 'Пермь', country: { id: 113, name: 'Россия', alpha2: 'RU' } },
  ],
  az: [
    { id: 5469592, name: 'Bakı', country: { id: 9, name: 'Azərbaycan', alpha2: 'AZ' } },
  ]
};
