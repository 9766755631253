import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_URL } from '@core/lib/api.factory';
import { Observable, of, throwError } from 'rxjs';
import {
  CartAddItem,
  CartChangeItemQuery,
  CartDeleteItem,
  CartDelivery, CartDeliveryQuery,
  CartIncrementQuery,
  CartItem, GqlCartDeliveryQuery,
  GqlCartQuery,
  DeliveryTypeIds,
  CartUpdateCityQuery,
  CartRewardIncrementQuery,
  CartRewardInfo,
  StockInfoQuery,
  GqlCartConditionsQuery,
  CartCondition,
  CartReward,
  GqlCartRewardInfoQuery,
  GqlCartRewardsQuery,
} from '@core/models';
import { catchError, map } from 'rxjs/operators';
import { ProductService } from '../product/product.service';
import { CityService } from '../hdbk/city.service';
import { StockService } from '../user/stock.service';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(
    private http: HttpClient,
    private productService: ProductService,
    @Inject(BASE_URL) private baseUrl: string,
    private cityService: CityService,
    private stockService: StockService
  ) { }

  getCart(): Observable<CartItem[]> {
    const countryId = this.cityService.getCountryId();
    const gqlQuery = new GqlCartQuery({country_id: countryId});
    return this.http.get<{data: CartItem[]}>(
      `${this.baseUrl}/store/cart?${gqlQuery.toRest()}`
    ).pipe(
      map( res => {
        res.data.forEach((cartItem: CartItem) => {
          this.productService.parseProductPrice(cartItem.product);
        });
        return res.data;
      } ),
      catchError(err => throwError(err))
    );
  }

  getCartDelivery(query?: CartDeliveryQuery): Observable<CartDelivery[]> {
    const gqlQuery = query ? new GqlCartDeliveryQuery(query) : new GqlCartDeliveryQuery();
    return this.http.get<{data: CartDelivery[]}>(`${this.baseUrl}/store/cart/delivery?${gqlQuery.toRest()}`)
      .pipe(
        map(res => {
          return res.data.filter(delivery => delivery.type.id !== DeliveryTypeIds.russian_post);
        })
      );
  }

  addToCart(item: CartAddItem): Observable<any> {
    item.amount = 1;
    return this.http.post(`${this.baseUrl}/store/cart`, item);
  }

  changeCartItem(query: CartChangeItemQuery): Observable<any> {
    const putQuery = Object.assign({}, query);
    if (putQuery.new_storage_id) {
      putQuery.storage_id = putQuery.new_storage_id;
      delete putQuery.new_storage_id;
    }
    return this.http.put(`${this.baseUrl}/store/cart/update`, putQuery);
  }

  deleteCartItem(item: CartDeleteItem): Observable<any> {
    const options = {
      headers: new HttpHeaders(),
      body: item.query
    };
    return this.http.delete(`${this.baseUrl}/store/cart`, options);
  }

  // deletes all items from a cart
  cleanCart(): Observable<any> {
    return this.http.delete(`${this.baseUrl}/store/cart`);
  }

  incrementCartItem(query: CartIncrementQuery): Observable<any> {
    return this.http.put(`${this.baseUrl}/store/cart/inc`, query);
  }

  decrementCartItem(query: CartIncrementQuery): Observable<any> {
    return this.http.put(`${this.baseUrl}/store/cart/dec`, query);
  }
  updateCartCity(query: CartUpdateCityQuery) {
    return this.http.put(`${this.baseUrl}/store/cart/update-multiple`, query);
  }

  getCartRewards(): Observable<CartReward[]> {
    const gqlQuery = new GqlCartRewardsQuery();
    return this.http.get<{data: CartReward[]}>(
      `${this.baseUrl}/store/cart-reward?${gqlQuery.toRest()}`
    ).pipe(map(res => res.data));
  }

  incrementCartRewardProduct(query: CartRewardIncrementQuery): Observable<CartRewardInfo> {
    const gqlQuery = new GqlCartRewardInfoQuery();
    return this.http.
        put<{data: CartRewardInfo}>(`${this.baseUrl}/store/cart-reward/inc?${gqlQuery.toRest()}`, query).pipe(map(res => res.data));
  }

  decrementCartRewardProduct(query: CartRewardIncrementQuery): Observable<CartRewardInfo>  {
    const gqlQuery = new GqlCartRewardInfoQuery();
    return this.http.
        put<{data: CartRewardInfo}>(`${this.baseUrl}/store/cart-reward/dec?${gqlQuery.toRest()}`, query).pipe(map(res => res.data));
  }

  updateCartRewardProduct(query: CartRewardIncrementQuery): Observable<CartRewardInfo>  {
    const gqlQuery = new GqlCartRewardInfoQuery();
    return this.http.
        put<{data: CartRewardInfo}>(`${this.baseUrl}/store/cart-reward/update?${gqlQuery.toRest()}`, query).pipe(map(res => res.data));
  }
  getStockInfo(reward_group_index: number): Observable<CartRewardInfo[]> {
    const query = new StockInfoQuery(reward_group_index);
    const gqlQuery = new GqlCartRewardInfoQuery(query);
    return this.http.get<{data: CartRewardInfo[]}>(
      `${this.baseUrl}/store/cart-reward/stock-info?${gqlQuery.toRest()}`
    ).pipe(map(res => res.data));
  }
  getStockConditions(): Observable<CartCondition[]> {
    if (this.stockService.activeStock$.getValue()) {
      const gqlQuery = new GqlCartConditionsQuery();
      return this.http.get<{data: CartCondition[]}>(
        `${this.baseUrl}/store/cart-reward/stock-conditions?${gqlQuery.toRest()}`
      ).pipe(map(res => res.data));
    } else {
      return of([]);
    }
  }

  selectCartItem(slugs: string) {
    return this.http.
        put<{data: CartRewardInfo}>(`${this.baseUrl}/store/cart/select`, { slugs });
  }

  deselectCartItem(slugs: string) {
    return this.http.
        put<{data: CartRewardInfo}>(`${this.baseUrl}/store/cart/unselect`, { slugs });
  }

  setCartRewards(cartItems: CartItem[], cartRewards: CartReward[]) {
    if(cartItems && cartItems.length > 0) {
      const rewardGroupIndexIds = [...new Set(cartRewards.map(item => item.reward_group_index))];
      for (const currentGroupIndex of rewardGroupIndexIds) {
        const groupedCartItems = cartItems.filter(item => item.reward_group_index === currentGroupIndex);
        if(groupedCartItems && groupedCartItems.length > 0) {
          const latestCartItemInGroup = groupedCartItems[groupedCartItems.length -1];
          latestCartItemInGroup.reward_products = cartRewards.filter((cartReward) => cartReward.reward_group_index === currentGroupIndex);
        }
      }
    }
  }
}
